import { FundTransferProvider } from '../../../../context/fund-transfer.context';
import style from '../../styles/style.module.css';
import RecentTransactionTab from '../fund-transfer/RecentTransactionTab';

const TransactionHistory = () => {

  return (
    <section className={`${style.main}`}>
      <FundTransferProvider>
        <RecentTransactionTab />
      </FundTransferProvider>
    </section>
  );
};
export default TransactionHistory;
