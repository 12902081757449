import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/charts/styles.css';
// import '@tailwind.css';
import { MantineProvider, MantineThemeOverride } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import './index.scss';

// Supports weights 100-900
// montserrat is similar to gotham
import '@fontsource-variable/montserrat';
import ContextProvider from './context';

const theme: MantineThemeOverride = {
  fontFamily: 'Montserrat Variable, sans-serif',
  headings: { fontFamily: 'Montserrat Variable, sans-serif' },
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <MantineProvider theme={theme}>
    <ContextProvider>
      <Notifications autoClose={8000} limit={5} position="top-right" />
      <App />
    </ContextProvider>
  </MantineProvider>
);

reportWebVitals();
