import { createContext, useContext, useState } from 'react';

// Create a context to manage ui-related data and functions
const UIContext = createContext<{
    openMobileNav: (value: boolean) => void;
    isOpenMobileNav: boolean;
    isLoading: boolean;
}>({
    openMobileNav: (value: boolean) => { },
    isOpenMobileNav: false,
    isLoading: false,
});

// Create a hook to access the UIContext
const useUI = () => useContext(UIContext);

// Create a component that provides ui-related data and functions
const UIProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isOpenMobileNav, setIsOpenMobileNav] = useState(false);

    const openMobileNav = (value: boolean) => {
        setIsOpenMobileNav((prev: boolean) => {
            prev = value;
            return prev;
        });
    };

    return (
        <UIContext.Provider
            value={{
                isOpenMobileNav,
                openMobileNav,
                isLoading,
            }}>
            {children}
        </UIContext.Provider>
    );
};

export { UIContext, UIProvider, useUI };
