import { Loader } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { MdOutlineContentCopy } from 'react-icons/md';

type PayloadProps = {
  transaction: Transaction | null;
  handleDownload: (id: string) => void;
  loading: boolean;
  setIsShowModalTransactionDetailsTitle?: (val: boolean) => void;
};

export default function TransactionDetails({
  transaction,
  handleDownload,
  loading,
  setIsShowModalTransactionDetailsTitle,
}: PayloadProps) {
  const handleCopy = async (data: string) => {
    if (!data) return;
    await navigator.clipboard.writeText(data);
    notifications.show({
      title: 'Clipboard',
      message: `${data} has been copied to clipboard`,
      autoClose: 5000,
    });
  };

  useEffect(() => {
    setIsShowModalTransactionDetailsTitle && setIsShowModalTransactionDetailsTitle(true);

    return () => {
      setIsShowModalTransactionDetailsTitle && setIsShowModalTransactionDetailsTitle(false);
    };
  }, []);


  return (
    <>
      {/* <button onClick={handleReturnToBulkList}>
        <span className="sr-only">Back</span>
        <svg
          width="16"
          height="19"
          viewBox="0 0 16 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_29_36066)">
            <path
              d="M0.335798 8.66118C-0.110631 9.12505 -0.110631 9.87837 0.335798 10.3422L6.05008 16.2797C6.49651 16.7436 7.22151 16.7436 7.66794 16.2797C8.11437 15.8159 8.11437 15.0625 7.66794 14.5987L3.90008 10.6874H14.8572C15.4894 10.6874 16.0001 10.1567 16.0001 9.49985C16.0001 8.84302 15.4894 8.31235 14.8572 8.31235H3.90366L7.66437 4.40103C8.1108 3.93716 8.1108 3.18384 7.66437 2.71997C7.21794 2.2561 6.49294 2.2561 6.04651 2.71997L0.332227 8.65747L0.335798 8.66118Z"
              fill="black"
            />
          </g>
          <defs>
            <clipPath id="clip0_29_36066">
              <rect width="16" height="19" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button> */}
      <ul className="text-xs">
        {transaction?.type !== 'credit' && (
          <li className="flex justify-between border-b-[1px] py-3 gap-2">
            <div>Requested by</div>
            <div className="font-semibold">
              {' '}
              {transaction?.initiator
                ? `${transaction?.initiator?.firstName} ${transaction?.initiator?.lastName}`
                : 'No Initiator'}
            </div>
          </li>
        )}

        <li className="flex justify-between border-b-[1px] py-3 gap-2">
          <div> {transaction?.type !== 'credit' ? 'Beneficiary' : 'Originating'} Bank</div>
          <div className="font-semibold">
            {' '}
            {transaction?.beneficiaryBankName || transaction?.originatorBankName || ''}
          </div>
        </li>

        <li className="flex justify-between border-b-[1px] py-3 gap-2">
          <div>{transaction?.type !== 'credit' ? 'Beneficiary' : 'Originating'} Account number</div>
          <div className="font-semibold">
            {transaction?.beneficiaryAccountNumber || transaction?.originatorAccountNumber}
          </div>
        </li>

        <li className="flex justify-between border-b-[1px] py-3 gap-2">
          <div>{transaction?.type !== 'credit' ? 'Beneficiary' : 'Originating'} Account name</div>
          <div className="font-semibold">
            {(transaction?.type !== 'credit'
              ? transaction?.beneficiaryAccountName
              : transaction?.originatorAccountName) || '-'}
          </div>
        </li>
        <li className="flex justify-between border-b-[1px] py-3 gap-2">
          <div>Reference Number</div>
          <div className="font-semibold flex justify-between gap-2">
            <div className="text-right text-nowrap overflow-hidden text-ellipsis w-[200px] min-w-[200px]">
              {transaction?.processorId || transaction?.sessionID || transaction?._id}
            </div>
            <button
              onClick={() => {
                const val = transaction?.processorId || transaction?.sessionID || transaction?._id;
                handleCopy(val || '');
              }}
              className="block cursor-pointer text-gray-400 hover:text-gray-600">
              <span className="sr-only">Copy</span>
              <MdOutlineContentCopy className="text-lg" />
            </button>
          </div>
        </li>

        <li className="flex justify-between border-b-[1px] py-3 gap-2">
          <div>Date and Time</div>
          <div className="font-semibold">
            {' '}
            {transaction?.createdAt
              ? dayjs(transaction?.createdAt).format('ddd, MMM DD YYYY hh:mm A')
              : 'NA'}
          </div>
        </li>

        <li className="flex justify-between border-b-[1px] py-3 gap-2">
          <div>Date and Time</div>
          <div className="font-semibold">
            {' '}
            {transaction?.createdAt
              ? dayjs(transaction?.createdAt).format('ddd, MMM DD YYYY hh:mm A')
              : 'NA'}
          </div>
        </li>

        <li className="flex justify-between border-b-[1px] py-3 gap-2">
          <div>Status</div>
          <div className="font-semibold">{transaction?.status}</div>
        </li>

        <li className="flex justify-between border-b-[1px] py-3 gap-2">
          <div>Narration</div>
          <div className="font-semibold">{transaction?.narration}</div>
        </li>
      </ul>

      {/* <div className="grid grid-cols-2  border-b-[1px] py-3 border-gray-200">
        <p className="text-xs ">
          {transaction?.type !== 'credit' ? 'Beneficiary' : 'Originating'} Bank
        </p>
        <p className="text-xs col-end-6 font-semibold">
          {transaction?.beneficiaryBankName || transaction?.originatorBankName || ''}
        </p>
      </div> */}

      {/* <div className="grid grid-cols-1  border-b-[1px] py-3 border-gray-200">
        <p className="text-xs ">
          {transaction?.type !== 'credit' ? 'Beneficiary' : 'Originating'} Account number
        </p>
        <p className="text-xs col-end-11 font-semibold">
          {transaction?.beneficiaryAccountNumber || transaction?.originatorAccountNumber}
        </p>
      </div> */}
      {/* <div className="grid grid-cols-1  border-b-[1px] py-3 border-gray-200">
        <p className="text-xs ">
          {transaction?.type !== 'credit' ? 'Beneficiary' : 'Originating'} Account name
        </p>
        <p className="text-xs col-end-6 font-semibold">
          {(transaction?.type !== 'credit'
            ? transaction?.beneficiaryAccountName
            : transaction?.originatorAccountName) || '-'}
        </p>
      </div> */}
      {/* <div className="flex gap-2 justify-between border-b-[1px] py-3 border-gray-200">
        <p className="text-xs ">Reference Number</p>
        <div className="text-xs col-end-6 font-semibold">
          <span className="block text-nowrap overflow-hidden text-ellipsis w-[200px] min-w-[200px] ">
            {transaction?.processorId || transaction?.sessionID || transaction?._id}
          </span>
        </div>
      </div> */}
      {/* <div className="grid grid-cols-2  border-b-[1px] py-3 border-gray-200">
        <p className="text-xs ">Date and Time</p>
        <h1 className="text-xs col-end-6 font-semibold">
          {transaction?.createdAt
            ? dayjs(transaction?.createdAt).format('ddd, MMM DD YYYY hh:mm A')
            : 'NA'}
        </h1>
      </div> */}
      {/* <div className="grid grid-cols-2  border-b-[1px] py-3 border-gray-200">
        <p className="text-xs ">Status</p>
        <h1 className="text-xs col-end-6 font-semibold">{transaction?.status}</h1>
      </div> */}
      {/* <div className="grid grid-cols-2  border-b-[1px] py-3 border-gray-200">
        <p className="text-xs ">Narration</p>
        <h1 className="text-xs col-end-6 font-semibold">{transaction?.narration}</h1>
      </div> */}

      <div className="grid grid-cols-1 w-2/5 mx-auto mt-5">
        {/* <div className="text-center ">
                  <div className="border border-[#1E253F] border-opacity-40 cursor-pointer rounded-md w-[55px] h-[50px] card shadow-lg mx-auto p-3">
                    <img
                      src="/assets/dash/share-icon.png"
                      alt="share icon"
                      width={21}
                      className="text-center mx-auto"
                    />
                  </div>
                  <p className="text-xs mt-2"> Share Receipt</p>
                </div> */}
        {transaction?.status === 'successful' ? (
          <div className="text-center">
            <button
              className="border border-[#1E253F] border-opacity-40 cursor-pointer rounded-md w-[55px] h-[50px] card shadow-lg mx-auto p-3  "
              onClick={() => handleDownload(transaction._id)}
              disabled={loading}>
              {loading ? (
                <Loader size={16} />
              ) : (
                <img
                  src="/assets/dash/download.png"
                  alt="download icon"
                  width={21}
                  className="text-center mx-auto"
                />
              )}
            </button>
            <p className="text-xs mt-2"> Download Receipt</p>
          </div>
        ) : null}
      </div>
    </>
  );
}
