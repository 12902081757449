import { Text, Container, PasswordInput, Button, Group, Loader } from '@mantine/core';
import { useForm } from '@mantine/form';
import { yupResolver } from 'mantine-form-yup-resolver';
import { useState } from 'react';
import { MdOutlineArrowBack } from 'react-icons/md';
import * as yup from 'yup';
import { apiEnable2fa, apiResetPassword } from '../../../api/ApiServices';
import { notifications } from '@mantine/notifications';

const NewPassWord = ({ onNextStep }: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const schema = yup.object().shape({
    password: yup
      .string()
      .required('Confirm Password is required')
      .min(8, 'Password should have at least 8 letters'),
    confirmPassword: yup
      .string()
      .required('Confirm Password is required')
      .min(8, 'Confirm Password should have at least 8 letters'),
  });

  const form = useForm({
    initialValues: {
      password: '',
      confirmPassword: '',
    },

    validate: yupResolver(schema),
  });

  // get token from  query
  const queryParameters = new URLSearchParams(window.location.search);
  const token = queryParameters.get('token');
  console.log(token);

  // change password
  const changePassword = async (params: any) => {
    try {
      setIsLoading(true);
      const payload = {
        ...params,
        token,
      };
      const resp = await apiResetPassword(payload);
      setIsLoading(false);
      
      if (resp?.data?.is2FAEnabled === true) {
        setTimeout(() => {
          window.location.href = '/login';
        }, 4000);
      } else {
        onNextStep();
      }
    } catch (error: any) {
      setError(
        notifications.show({
          title: 'Something went wrong',
          color: 'red',
          message: error?.message || 'An error occurred, please try again later.',
        })
      );
      console.log(error);
    } finally {
      setIsLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      <Container px={10}>
        <Text ta="left" size="xl" mt={20} fw={500}>
          Change Password
        </Text>
        <Text ta="left" mb={10} className="text-xs">
          To change your passport, please fill in the field below.
        </Text>
        <ol>
          <li>Your password must contain at least 8 characters</li>
          <li>It must also include at least One upper case letter</li>
          <li>One lower case letter and One number.</li>
        </ol>
        <form onSubmit={form.onSubmit(changePassword)}>
          <PasswordInput
            radius={12}
            mt={18}
            placeholder="New password"
            {...form.getInputProps('password')}
          />
          <PasswordInput
            radius={12}
            mt={18}
            placeholder="Confirm new password"
            {...form.getInputProps('confirmPassword')}
          />
          <Group justify="right" mt={60}>
            <Button
              variant="gradient"
              gradient={{ from: '#24E3F2', to: '#0798D0', deg: 90 }}
              w={180}
              radius={12}
              mx={10}
              type="submit"
              disabled={isLoading}>
              {isLoading ? <Loader size={20} /> : 'Change Password'}
            </Button>
          </Group>
        </form>
      </Container>
    </>
  );
};

export default NewPassWord;
