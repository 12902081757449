import { BackgroundImage, Center, Loader } from '@mantine/core';
import style from '../../styles/style.module.css';
import { data2 } from '../../data';
import { DonutChart } from '@mantine/charts';
import { useDisclosure } from '@mantine/hooks';
import RecentTransactionTab from './RecentTransactionTab';
import { useEffect, useMemo, useState } from 'react';
import Balance from '../common/Balance';
import BulkModal from './component/bulkModal';
import Modal from './component/modal';
import { apiFrequentBeneficiary } from '../../../api/ApiServices';
import { notifications } from '@mantine/notifications';
import { useAccount } from '../../../../context/account.context';
import { FundTransferProvider } from '../../../../context/fund-transfer.context';

const FundsTransfer = () => {
  const [modalOpened, { open: openModal, close: closeModal }] = useDisclosure(false);
  const [bulkModalOpened, { open: openBulkModal, close: closeBulkModal }] = useDisclosure(false);
  const [isLoading, setIsLoading] = useState(false);
  const [totalBeneVal, setTotalBeneVal] = useState(0);

  const [error, setError] = useState<string | null>(null);
  const [beneficiary, setBeneficiary] = useState<{ accountName: string; frequency: number }[]>();
  const [isEmpty, setIsEmpty] = useState<boolean>(true);
  const { currentAccount } = useAccount();

  const beneficiaries = async () => {
    try {
      setIsLoading(true);
      const resp = await apiFrequentBeneficiary();
      setBeneficiary(resp.data.beneficiaries);
      setIsEmpty(resp.data?.beneficiaries?.length === 0);
      setIsLoading(false);
    } catch (error: any) {
      setError(
        notifications.show({
          color: 'red',
          message: error?.message || 'An error occurred, please try again later.',
        })
      );
      setIsLoading(false);
    }
  };

  useEffect(() => {
    beneficiaries();
  }, [currentAccount]);

  const colorArray = [
    '#4285F4',
    '#FF6633',
    '#800080',
    '#008000',
    '#F7D354',
    '#6699CC',
    '#7C3AED',
    '#FF3380',
  ];

  // const donutBeneficiary = useMemo(() => {
  //   const formatBeneficiary = beneficiary || [];
  //   setTotalBeneVal(formatBeneficiary.reduce((acc, curr) => acc + curr.frequency || 0, 0));
  //   return formatBeneficiary.map((bene, idx) => {
  //     return {
  //       name: bene.accountName,
  //       value: bene.frequency,
  //       color:
  //         colorArray[idx] || '#' + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, '0'),
  //     };
  //   });
  // }, [beneficiary]);

  const donutBeneficiary = useMemo(() => {
    const formatBeneficiary = beneficiary || [];
    setTotalBeneVal(formatBeneficiary.reduce((acc, curr) => acc + curr.frequency || 0, 0));
    return formatBeneficiary.map((bene, idx) => {
      return {
        name: bene.accountName,
        value: bene.frequency,
        color:
          colorArray[idx] || '#' + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, '0'),
      };
    });
  }, [beneficiary]);

  donutBeneficiary.sort((a, b) => b.value - a.value);
  return (
    <>
      <section className={`${style.main}`}>
        <section className="grid grid-cols-2 items-stretch gap-4">
          <div className="col-span-2 lg:col-span-1">
            <BackgroundImage
              src="/assets/dash/dashboard-bg.png"
              className="p-6 rounded-lg shadow-sm h-full flex flex-col justify-center">
              <h1 className="text-white font-medium mb-5">Quick Transfer</h1>
              <div className="overflow-auto">
                <div className="grid grid-cols-12 gap-4 flex-nowrap w-screen sm:w-auto">
                  <div className="card col-span-4 rounded-lg shadow-lg bg-white p-3 text-center cursor-not-allowed">
                    <img
                      src="/assets/dash/saved.png"
                      alt="saved icon"
                      width={30}
                      className=" mx-auto my-2"
                    />
                    <p className="text-xs font-medium mt-5">Saved Beneficiary</p>
                  </div>
                  <div
                    onClick={openModal}
                    className="card col-span-4 rounded-lg shadow-lg bg-white p-3 text-center cursor-pointer">
                    <img
                      src="/assets/dash/add.png"
                      alt="saved icon"
                      width={30}
                      className=" mx-auto my-2"
                    />
                    <p className="text-xs font-medium mt-5">New Beneficiary</p>
                  </div>
                  <div
                    className="card col-span-4 rounded-lg shadow-lg bg-white p-3 text-center cursor-pointer"
                    onClick={openBulkModal}>
                    <img
                      src="/assets/dash/send.png"
                      alt="saved icon"
                      width={30}
                      className=" mx-auto my-2"
                    />
                    <p className="text-xs font-medium mt-5">Bulk Transfer</p>
                  </div>
                </div>
              </div>
            </BackgroundImage>
          </div>

          <div className="min-h-64 lg:min-h-[auto] col-span-2 lg:col-span-1 rounded-lg bg-white p-6 shadow-sm">
            {!isLoading && isEmpty ? (
              <div className="flex items-center justify-center h-full">
                <img src="/assets/dash/empty-ben.png" alt="empty state" className="w-[6rem]" />
                <p className="text-sm my-auto">No recent beneficiaries found.</p>
              </div>
            ) : isLoading ? (
              <Center>
                <Loader size={30} className="my-24" />
              </Center>
            ) : (
              <>
                <p className=" font-medium text-xs  my-4">Frequent Beneficiaries</p>
                <div className=" my-1 flex flex-col lg:flex-row gap-6 lg:gap-10">
                  <DonutChart
                    size={120}
                    thickness={28}
                    tooltipDataSource="segment"
                    data={donutBeneficiary}
                  />
                  <div>
                    {donutBeneficiary.map((bene, idx) => (
                      <div key={idx} className="grid grid-cols-12 gap-2 space-y-1">
                        <div className="col-span-10 flex space-x-4 my-auto">
                          <div
                            className="rounded-[100%] h-3 w-3 my-auto !capitalize"
                            style={{ backgroundColor: bene.color }}></div>
                          <p className="text-sm">{bene.name.toLocaleLowerCase()}</p>
                        </div>
                        <div>
                          <p className="col-end-12 text-sm">
                            {((bene.value / totalBeneVal) * 100).toFixed(2)}%
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
          </div>
        </section>

        {/* chart section */}
        <FundTransferProvider>
          <RecentTransactionTab />
        </FundTransferProvider>
      </section>

      <Modal opened={modalOpened} open={openModal} close={closeModal} />
      <BulkModal opened={bulkModalOpened} open={openBulkModal} close={closeBulkModal} />
    </>
  );
};

export default FundsTransfer;
