import { SetStateAction, useEffect, useState } from 'react';
import {
  Card,
  Container,
  BackgroundImage,
  Text,
  Flex,
  Button,
  Stepper,
  Modal,
} from '@mantine/core';
import EmailCode from '../common/EmailCode';
import SuccessMessage from '../common/SuccessMessage';
import BusinessSignatories from '../soleProprietorshipAccount/component/BusinessSignatories';
import DirectorDetails from './component/DirectorDetails';
import RegisterDoc from './component/RegisterDoc';
import RegisteredIntro from './component/RegisteredIntro';

const RegisteredAssociation = () => {

    const [active, setActive] = useState(0);
    const [showSuccess, setShowSuccess] = useState(false);
    const [formData, setFormData] = useState({});
    const [step, setStep] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const steps = [
      'RegisteredIntro',
      'EmailCode',
      'RegisterDoc',
      'DirectorDetails',
      'BusinessSignatories',
      'SuccessMessage',
    ];
    const nextStep = () => setActive((current) => Math.min(current + 1, steps.length - 1));
    const prevStep = () => setActive((current) => Math.max(current - 1, 0));
    const handleNextStep = () => {
      nextStep();
      setStep((prevStep) => prevStep + 1);
    };
    const updateFormData = (newData: SetStateAction<{}>) => {
      setFormData((prevFormData) => ({ ...prevFormData, ...newData }));
    };

    const handleFinishClick = () => {
      if (active === steps.length - 2) {
        setShowSuccess(true);
        console.log(formData);
      } else {
        nextStep();
      }
    };

    useEffect(() => {
      const storedStep = parseInt(localStorage.getItem('registeredBStep') || '');
      console.log('Retrieved registeredBStep from localStorage:', storedStep);
      setActive(!isNaN(storedStep) ? storedStep : 0);
      setIsLoading(false);
    }, []);

    useEffect(() => {
      console.log('Setting registeredBStep in localStorage:', active);
      localStorage.setItem('registeredBStep', String(active));
    }, [active]);

    if (isLoading) {
      return <div>Loading...</div>;
    }
    
  return (
    <>
      <Container px={10}>
        <Card shadow="md" padding="xl" radius="md" maw={650} mx="auto">
          <Card.Section mx={-60}>
            <BackgroundImage src="/assets/form-bg.png" h={90}>
              <Flex
                direction={{ base: 'column', sm: 'row' }}
                gap={40}
                justify={{ sm: 'center' }}
                pt={5}>
                <Text size="lg" c="white" mt={10}>
                  Let’s get you started with a
                  <span className="block font-[700]">Source Business Account</span>
                </Text>
              </Flex>
            </BackgroundImage>
          </Card.Section>

          {!showSuccess && (
            <>
              <Stepper active={active} size="xs" mt={35} mb={40} onStepClick={setActive}>
                <Stepper.Step disabled>
                  <RegisteredIntro onNextStep={handleNextStep} updateFormData={updateFormData} />
                </Stepper.Step>
                <Stepper.Step disabled>
                  <EmailCode
                    onPrevStep={prevStep}
                    formData={formData}
                    onNextStep={handleNextStep}
                    step={step}
                  />
                </Stepper.Step>
                <Stepper.Step disabled>
                  <RegisterDoc
                    onPrevStep={prevStep}
                    onNextStep={handleNextStep}
                    updateFormData={updateFormData}
                  />
                </Stepper.Step>
                <Stepper.Step disabled>
                  <DirectorDetails
                    onPrevStep={prevStep}
                    onNextStep={handleNextStep}
                    updateFormData={updateFormData}
                  />
                </Stepper.Step>
                <Stepper.Step disabled>
                  <BusinessSignatories
                    onPrevStep={prevStep}
                    handleFinishClick={handleFinishClick}
                  />
                </Stepper.Step>
              </Stepper>
            </>
          )}
          {showSuccess && (
            <>
              <SuccessMessage />
            </>
          )}
        </Card>
      </Container>
    </>
  );
};

export default RegisteredAssociation;
