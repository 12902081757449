// import { useEffect, useState } from 'react';
// import style from '../../styles/style.module.css';
// import { MdKeyboardArrowDown } from 'react-icons/md';
// import { Button, Menu } from '@mantine/core';
// import { useAccount } from '../../../../context/account.context';

// const Header = () => {
//   const { accounts, currentAccount, switchAccount, getBusinessAccounts, isLoading } = useAccount();

//   useEffect(() => {
//     getBusinessAccounts();
//   }, []);

//   return (
//     <>
//       <section className={`${style.header} bg-black p-3 h-20 z-20`}>
//         <div className="w-[98%] mx-auto text-white grid grid-cols-8">
//           <div className="col-span-7">
//             <img src="/assets/dash/dash-logo.png" alt="source logo" width={140} height={140} />
//           </div>

//           <div className="my-auto col-end-11">
//             <Menu>
//               <Menu.Target>
//                 <Button
//                   p={0}
//                   m={0}
//                   className="!bg-transparent shadow-lg !text-white "
//                   loading={isLoading}>
//                   {currentAccount
//                     ? `${currentAccount.name} (${currentAccount.number})`
//                     : 'Select Account'}
//                   {!isLoading && <MdKeyboardArrowDown className="my-auto mx-1" />}
//                 </Button>
//               </Menu.Target>

//               <Menu.Dropdown>
//                 {accounts?.map((account: Account) => (
//                   <Menu.Item
//                     key={account._id}
//                     onClick={() => {
//                       if (accounts.length === 0) {
//                         getBusinessAccounts();
//                       }
//                       switchAccount(account._id);
//                     }}>
//                     {account ? `${account.name} (${account.number})` : 'Select Account'}
//                   </Menu.Item>
//                 ))}
//               </Menu.Dropdown>
//             </Menu>
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };

// export default Header;


import { useEffect, useState } from 'react';
import style from '../../styles/style.module.css';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { Button, Menu } from '@mantine/core';
import { useAccount } from '../../../../context/account.context';
import brandLogo from "../../../../public/assets/images/brand-logo.svg";
import { Link } from 'react-router-dom';
import { useUI } from '../../../../context/ui.context';

const Header = () => {
  const { accounts, currentAccount, switchAccount, getBusinessAccounts, isLoading } = useAccount();  

  useEffect(() => {
    getBusinessAccounts();
  }, []);

  return (
    <>
      <nav className="bg-black">
        <div className="px-4 xl:px-8">
          <div className="relative flex h-16 py-12 items-center justify-between">
            <div className="flex flex-1 items-center justify-start sm:items-stretch sm:justify-start">
              <div className="flex flex-shrink-0 items-center">
                <Link to="#">            {/* <img src="/assets/dash/dash-logo.png" alt="source logo" width={140} height={140} /> */}
                  <img src={brandLogo} alt="source logo" width={200} className='hidden lg:block' />
                  <img src={brandLogo} alt="source logo" width={150} className='lg:hidden' />
                </Link>
              </div>
            </div>
            <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
              <Menu>
                <Menu.Target>
                  <Button
                    p={0}
                    m={0}
                    className="!bg-transparent shadow-lg !text-white "
                    loading={isLoading}>
                    {currentAccount
                      ? `${currentAccount.name} (${currentAccount.number})`
                      : 'Select Account'}
                    {!isLoading && <MdKeyboardArrowDown className="my-auto mx-1" />}
                  </Button>
                </Menu.Target>

                <Menu.Dropdown>
                  {accounts?.map((account: Account) => (
                    <Menu.Item
                      key={account._id}
                      onClick={() => {
                        if (accounts.length === 0) {
                          getBusinessAccounts();
                        }
                        switchAccount(account._id);
                      }}>
                      {account ? `${account.name} (${account.number})` : 'Select Account'}
                    </Menu.Item>
                  ))}
                </Menu.Dropdown>
              </Menu>
            </div>
          </div>
        </div>
      </nav>


      {/* hidden */}
      <section className={`${style.header} bg-black p-3 z-20 hidden`}>
        <div className="w-[98%] mx-auto text-white grid grid-cols-8">
          <div className="col-span-7">
            {/* <img src="/assets/dash/dash-logo.png" alt="source logo" width={140} height={140} /> */}
            <img src={brandLogo} alt="source logo" width={200} className='hidden lg:block' />
            <img src={brandLogo} alt="source logo" width={150} className='lg:hidden' />
          </div>

          <div className="my-auto col-end-11">
            <Menu>
              <Menu.Target>
                <Button
                  p={0}
                  m={0}
                  className="!bg-transparent shadow-lg !text-white "
                  loading={isLoading}>
                  {currentAccount
                    ? `${currentAccount.name} (${currentAccount.number})`
                    : 'Select Account'}
                  {!isLoading && <MdKeyboardArrowDown className="my-auto mx-1" />}
                </Button>
              </Menu.Target>

              <Menu.Dropdown>
                {accounts?.map((account: Account) => (
                  <Menu.Item
                    key={account._id}
                    onClick={() => {
                      if (accounts.length === 0) {
                        getBusinessAccounts();
                      }
                      switchAccount(account._id);
                    }}>
                    {account ? `${account.name} (${account.number})` : 'Select Account'}
                  </Menu.Item>
                ))}
              </Menu.Dropdown>
            </Menu>
          </div>
        </div>
      </section>
    </>
  );
};

export default Header;
