import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { useNavigate } from 'react-router-dom';

const BASE_URL = process.env.REACT_APP_BASE_URL || '/api/v1/';

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 50000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    // You can add any other default headers here
  },
});

// Function to handle logout
const handleLogout = () => {
  localStorage.removeItem('token');
  window.location.href = '/login';
};

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  (error: any) => {
    if (error.response?.status === 401) {
      const errMsg = String(error.response?.data?.message).trim().toLowerCase();

      if (errMsg === 'invalid credentials' || errMsg === 'invalid otp') {
        console.log(errMsg);
      } else {
        handleLogout();
        localStorage.clear();
        // console.log(error);
      }
    }
    return Promise.reject(error);
  }
);

// Define a generic fetchData function
export async function fetchData<T>(config: AxiosRequestConfig): Promise<T> {
  try {
    const response = await axiosInstance.request(config);
    return response.data;
  } catch (error: unknown) {
    const err = error as AxiosError;
    throw err.response?.data || err.response || err;
  }
}

export default axiosInstance;
