import style from '../../styles/style.module.css';
import { Button } from '@mantine/core';
import { RxArrowTopRight } from 'react-icons/rx';
import { AiOutlineMail } from 'react-icons/ai';
import { IoLogoWhatsapp } from 'react-icons/io5';
import { IoCallOutline } from 'react-icons/io5';
import FAQs from './component/Accordian';

const Support = () => {
  return (
    <section className={`${style.statement}`}>
      <h1 className="font-bold me-11 my-2 mb-10 text-lg ">Contact Support</h1>
      <div className="bg-[#ffffff] rounded-xl p-4 lg:px-10 lg:py-5 mb-5 min-h-[75vh] ">
        <h2 className="my-5 flex flex-col justify-start items-start gap-5 pb-8 border-gray-200 border-b-2">
          <p className="font-bold">Check out our user guide and how-to’s:</p>

          <a href="/assets/user-guide.pdf" download="UserGuide.pdf">
            <Button
              variant="outline"
              color="dark"
              radius="lg"
              size="md"
              rightSection={<RxArrowTopRight color="blue" fontSize="1.5rem" fontWeight="bold" />}>
              Download User Guide
            </Button>
          </a>
        </h2>

        <div>
          <h2 className="font-bold my-5">Talk with our team</h2>
          <div className="flex flex-col justify-center items-start gap-3 pb-8 border-gray-200 border-b-2">
            <a
              href="mailto:support@mysourcebank.com"
              title="Email: support@mysourcebank.com"
              className="flex w-full justify-between items-center gap-10 lg:w-2/5 hover:bg-blue-100 hover:px-3 py-4 duration-300 rounded-md">
              <div className="flex justify-start items-center gap-4">
                <span className="bg-blue-300/80 rounded-full p-2">
                  <AiOutlineMail fontSize="1.5rem" />
                </span>
                <div className="text-left">
                  <h3 className="font-semibold">Email us</h3>
                  <p className="text-xs">Send us an email and we will respond soon</p>
                </div>
              </div>
              <div>
                <RxArrowTopRight color="blue" fontSize="1.5rem" fontWeight="bold" />
              </div>
            </a>

            <a
              href="https://wa.me/+2349139375297"
              title="Chat: +2349139375297"
              target="_blank"
              className="flex w-full justify-between items-center gap-10 lg:w-2/5 hover:bg-blue-100 hover:px-3 py-4 duration-300 rounded-md">
              <div className="flex justify-start items-center gap-4">
                <span className="bg-blue-300/80 rounded-full p-2">
                  <IoLogoWhatsapp fontSize="1.5rem" />
                </span>
                <div className="text-left">
                  <h3 className="font-semibold">Chat with us</h3>
                  <p className="text-xs">Send us a message via WhatsApp</p>
                </div>
              </div>
              <div>
                <RxArrowTopRight color="blue" fontSize="1.5rem" fontWeight="bold" />
              </div>
            </a>

            <a
              href="tel:+2349139375297"
              title="Call: +2349139375297"
              className="flex w-full justify-between items-center gap-10 lg:w-2/5 hover:bg-blue-100 hover:px-3 py-4 duration-300 rounded-md">
              <div className="flex justify-start items-center gap-4">
                <span className="bg-blue-300/80 rounded-full p-2">
                  <IoCallOutline fontSize="1.5rem" />
                </span>
                <div className="text-left">
                  <h3 className="font-semibold">Call us</h3>
                  <p className="text-xs">Available Monday to Friday, from 9am-5pm </p>
                </div>
              </div>
              <div>
                <RxArrowTopRight color="blue" fontSize="1.5rem" fontWeight="bold" />
              </div>
            </a>
          </div>
        </div>

        <div>
          <h2 className="font-bold my-5">Read our FAQs</h2>

          <article className="pb-24">
            <FAQs />
          </article>
        </div>
      </div>
    </section>
  );
};

export default Support;
