import React, { useEffect, useState } from 'react';
import { getCBNTransferFee } from '../modal/Step2';
import { apiGetBatchTransactions, apiTranReceipt } from '../../../../../api/ApiServices';
import { notifications } from '@mantine/notifications';
import numeral from 'numeral';
import dayjs from 'dayjs';
import { Loader } from '@mantine/core';
import BulkTransferTable from './BulkTransferTable';
import TransactionDetails from './TransactionDetails';
import { useFundTransfer } from '../../../../../../context/fund-transfer.context';
import { initialBulkTransactionsData } from './data/bulk-transactions';

type PayloadProps = {
  setModalSize: (payload: number) => void;
};

enum TransferKind {
  Bulk = 'bulk_transfer',
  SINGLE = 'single_transfer',
}

export default function Receipt({ setModalSize }: PayloadProps) {
  /**fund transfer context */
  const {
    selectedTransaction,
    setBulkTransferList,
    setIsOpenDetailsView,
    isBulkTransfer,
    setIsBulkTransfer,
    selectedBulkTransactionItem,
    setSelectedBulkTransactionItem,
  } = useFundTransfer();

  /** */

  const [loading, setLoading] = useState(false);
  const [isOneDownloading, setIsOneDownloading] = useState(false);
  const [isShowModalTransactionDetailsTitle, setIsShowModalTransactionDetailsTitle] =
    useState(false);
  const [amount, setAmount] = useState<number>(0);

  const handleDownload = async (id: string) => {
    if (!isOneDownloading) {
      setIsOneDownloading(true);
    } else {
      return false;
    }
    setLoading(true);

    try {
      // await new Promise((res) => setTimeout(res, 5000));
      const data = await apiTranReceipt(id);
      const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
      const hideLink = document.createElement('a');
      hideLink.style.display = 'none';
      hideLink.href = url;
      hideLink.download = `${selectedTransaction?.beneficiaryAccountName || ''} Receipt.pdf`;
      document.body.appendChild(hideLink);
      hideLink.click();
      document.body.removeChild(hideLink);
      setLoading(false);
      setIsOneDownloading(false);

      // const blob = new Blob([response.data], { type: 'application/pdf' });
      // console.log('Blob created', {
      //   size: blob.size,
      //   type: blob.type,
      // });

      // saveAs(blob, `receipt_${id}.pdf`);
      // console.log('saveAs called');
    } catch (error: any) {
      console.error('Error in handleDownload:', error);
      setLoading(false);
      setIsOneDownloading(false);

      notifications.show({
        title: 'Download Failed',
        color: 'red',
        message: error.message || 'An error occurred, please try again later.',
      });
    }
  };

  const handleBackHistory = () => {
    setSelectedBulkTransactionItem(null);
  };

  useEffect(() => {
    setAmount((prev: number) => {
      prev = Number(selectedTransaction?.amount);
      return prev;
    });
  }, [selectedTransaction]);

  // useEffect(() => {
  //   console.log({ amount });
  // }, [amount]);

  useEffect(() => {
    if (isBulkTransfer && selectedBulkTransactionItem?.batchId === selectedTransaction?.batchId) {
      setAmount((prev: number) => {
        prev = Number(selectedBulkTransactionItem?.amount);
        return prev;
      });
    } else {
      setAmount((prev: number) => {
        prev = Number(selectedTransaction?.amount);
        return prev;
      });
    }
  }, [selectedBulkTransactionItem, selectedTransaction]);

  useEffect(() => {
    if (selectedTransaction?.batchGroup) {
      setModalSize(700);
      setIsShowModalTransactionDetailsTitle(false);
      setIsBulkTransfer && setIsBulkTransfer(true);
      const batchId = selectedTransaction?.batchId || '';

      /**fetch batch transaction */
      const fetchTransactions = async () => {
        try {
          const response = await apiGetBatchTransactions(batchId);
          if (response && response.data) {
            // setBulkTransferList(initialBulkTransactionsData); //demo;
            setBulkTransferList(response.data.transactions);
          }
        } catch (error) {
          console.error({ error });
        }
      };

      fetchTransactions();
    } else {
      setModalSize(513);
      setIsShowModalTransactionDetailsTitle(true);
      setIsBulkTransfer && setIsBulkTransfer(false);
    }
  }, [selectedTransaction]);

  return (
    <div className="lg:px-4">
      <div className="grid grid-cols-2 mb-8">
        <div className="w-32">
          <img src="/assets/logo.png" alt="logo" className="w-full" />
        </div>
        {isShowModalTransactionDetailsTitle && (
          <h1 className="col-end-6 font-semibold text-xs my-auto">Transaction Details</h1>
        )}
      </div>
      {/*  */}

      <main>
        <div className="text-center my-9">
          <h2 className="text-xs font-semibold">Transaction Amount</h2>
          <p className="col-end-6 font-semibold text-xl mt-2">
            ₦{numeral(amount).format('0,0.00')}
          </p>
          <hr className="w-1/2 mx-auto mt-2" />
        </div>

        {isBulkTransfer && (
          <section>
            {/* Bulk transafer details */}
            {!selectedBulkTransactionItem && (
              <>
                <h2 className="text-base font-semibold mb-5">Beneficiaries</h2>
                <BulkTransferTable
                  handleDownload={handleDownload}
                  isOneDownloading={isOneDownloading}
                  setIsOneDownloading={setIsOneDownloading}
                />
                {/*  */}

                <div className="grid grid-cols-1 w-2/5 mx-auto mt-5">
                  {/* <div className="text-center ">
                  <div className="border border-[#1E253F] border-opacity-40 cursor-pointer rounded-md w-[55px] h-[50px] card shadow-lg mx-auto p-3">
                    <img
                      src="/assets/dash/share-icon.png"
                      alt="share icon"
                      width={21}
                      className="text-center mx-auto"
                    />
                  </div>
                  <p className="text-xs mt-2"> Share Receipt</p>
                </div> */}

                  {selectedTransaction?.status === 'successful' ? (
                    <div className="text-center">
                      <button
                        className="border border-[#1E253F] border-opacity-40 cursor-pointer rounded-md w-[55px] h-[50px] card shadow-lg mx-auto p-3  disabled:cursor-not-allowed"
                        onClick={() => handleDownload(selectedTransaction?._id)}
                        disabled={loading || isOneDownloading}>
                        {loading ? (
                          <Loader size={16} />
                        ) : (
                          <img
                            src="/assets/dash/download.png"
                            alt="download icon"
                            width={21}
                            className="text-center mx-auto"
                          />
                        )}
                      </button>
                      <p className="text-xs mt-2"> Download Bulk Receipt</p>
                    </div>
                  ) : null}
                </div>
              </>
            )}

            <>
              {selectedBulkTransactionItem && (
                <div>
                  <button onClick={handleBackHistory}>
                    <span className="sr-only">Back</span>
                    <svg
                      width="16"
                      height="19"
                      viewBox="0 0 16 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_29_36066)">
                        <path
                          d="M0.335798 8.66118C-0.110631 9.12505 -0.110631 9.87837 0.335798 10.3422L6.05008 16.2797C6.49651 16.7436 7.22151 16.7436 7.66794 16.2797C8.11437 15.8159 8.11437 15.0625 7.66794 14.5987L3.90008 10.6874H14.8572C15.4894 10.6874 16.0001 10.1567 16.0001 9.49985C16.0001 8.84302 15.4894 8.31235 14.8572 8.31235H3.90366L7.66437 4.40103C8.1108 3.93716 8.1108 3.18384 7.66437 2.71997C7.21794 2.2561 6.49294 2.2561 6.04651 2.71997L0.332227 8.65747L0.335798 8.66118Z"
                          fill="black"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_29_36066">
                          <rect width="16" height="19" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                </div>
              )}
            </>
          </section>
        )}

        {isBulkTransfer &&
          selectedBulkTransactionItem?.batchId === selectedTransaction?.batchId && (
            <TransactionDetails
              setIsShowModalTransactionDetailsTitle={setIsShowModalTransactionDetailsTitle}
              transaction={selectedBulkTransactionItem}
              handleDownload={handleDownload}
              loading={loading}
            />
          )}

        {
          <>
            {!isBulkTransfer && (
              <TransactionDetails
                setIsShowModalTransactionDetailsTitle={setIsShowModalTransactionDetailsTitle}
                transaction={selectedTransaction}
                handleDownload={handleDownload}
                loading={loading}
              />
            )}
          </>
        }
      </main>
    </div>
  );
}
