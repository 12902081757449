import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { DatePicker, DateValue, DatesRangeValue } from '@mantine/dates';
import dayjs from 'dayjs';
import numeral from 'numeral';
import { Menu, Button, Popover } from '@mantine/core';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { apiAccount } from '../../../api/ApiServices';
import { useAccount } from '../../../../context/account.context';

export type DateRange =
  | {
      startDate: DateValue;
      endDate: DateValue;
    }
  | undefined;

export type DateRangeRef = {
  getDates: () => DateRange;
};

interface DateRangeProps {
  ref?:
    | React.Ref<DateRangeRef>
    | React.MutableRefObject<DateRangeRef>
    | React.LegacyRef<DateRangeRef>;
  dateRangeCallback: (dateRange?: DateRange) => void;
}

const Balance: React.FC<DateRangeProps> = forwardRef<DateRangeRef, DateRangeProps>(
  ({ dateRangeCallback }, ref) => {
    const [account, setAccount] = useState<Account>();
    const [selectedPeriod, setSelectedPeriod] = useState('Current Week');
    const [popoverOpened, setPopoverOpened] = useState(false);
    const [dateRange, setDateRange] = useState<DateRange>({
      startDate: null,
      endDate: null,
    });
    const { currentAccount } = useAccount();

    const datePickerRef = useRef<HTMLDivElement>(null);
    const popoverContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (confirmSelectedDates()) {
        dateRangeCallback(dateRange);
        setTimeout(() => {
          // add a little delay
          closePopover();
        }, 500);
      }
    }, [dateRange]);

    const confirmSelectedDates = () => {
      const { startDate, endDate } = dateRange || {};

      if (!startDate || !endDate) {
        console.log({
          message: 'Both start and end dates are required before a statement can be generated',
          type: 'danger',
        });
        return false;
      }

      const mStartDate = dayjs(startDate);
      const mEndDate = dayjs(endDate);

      if (mStartDate.isAfter(mEndDate)) {
        console.log({
          message: 'Start date must be before the end date',
          type: 'danger',
        });
        return false;
      }

      return true;
    };
    // get single business account
    const getAccount = async () => {
      // closePopover();
      try {
        const response = await apiAccount();
        setAccount(response.data.accounts);
        // console.log(response);
      } catch (error) {
        console.log(error);
      }
    };

    useEffect(() => {
      getAccount();
    }, [currentAccount]);

    const handlePeriodClick = (period: string) => {
      setSelectedPeriod(period);
      const dateVar = dayjs();

      if (period === 'Current Year') {
        setDateRange({
          startDate: dateVar.startOf('year').toDate(),
          endDate: dateVar.endOf('year').toDate(),
        });
      } else if (period === 'Current Month') {
        setDateRange({
          startDate: dateVar.startOf('month').toDate(),
          endDate: dateVar.endOf('month').toDate(),
        });
      } else if (period === 'Current Week') {
        setDateRange({
          startDate: dateVar.startOf('week').toDate(),
          endDate: dateVar.endOf('week').toDate(),
        });
      } else if (period === 'Custom Date') {
        setPopoverOpened(true);
        return;
      }

      // setPopoverOpened(false);
    };

    const getDates = () => {
      if (confirmSelectedDates()) {
        return dateRange;
      }
      return undefined;
    };

    useImperativeHandle(ref, () => ({
      getDates,
    }));

    const closePopover = () => {
      setPopoverOpened(false);
    };

    return (
      <div className="flex justify-between items-center flex-wrap lg:flex-nowrap gap-4">
        
        <div className="flex gap-5 flex-wrap w-full max-w-[400px] lg:flex-nowrap">
          <div className="card rounded-lg shadow-sm w-full my-auto bg-white p-5">
            <p className="font-bold my-1">
              ₦{numeral(account?.balance?.ledger || 0).format('0,0.00')}
            </p>
            <p className="text-xs text-[#0798D0] font-bold">Ledger Balance</p>
          </div>
          <div className="card rounded-lg shadow-sm w-full my-auto bg-white p-5">
            <p className="font-bold my-1">
              ₦{numeral(account?.balance?.available || 0).format('0,0.00')}
            </p>
            <p className="text-xs text-[#0798D0] font-bold">Available Balance</p>
          </div>
        </div>




        <div className="w-full flex justify-end" ref={datePickerRef}>
          <Popover opened={popoverOpened}>
            <Popover.Target>
              <Menu shadow="md" width={200}>
                <Menu.Target>
                  <Button
                    className="!bg-white shadow-sm !text-black flex justify-between">
                    {selectedPeriod}
                    <MdKeyboardArrowDown className="my-auto mx-1" />
                  </Button>
                </Menu.Target>

                <Menu.Dropdown>
                  <Menu.Item onClick={() => handlePeriodClick('Current Week')}>
                    Current Week
                  </Menu.Item>
                  <Menu.Item onClick={() => handlePeriodClick('Current Month')}>
                    Current Month
                  </Menu.Item>
                  <Menu.Item onClick={() => handlePeriodClick('Current Year')}>
                    Current Year
                  </Menu.Item>
                  <Menu.Item onClick={() => handlePeriodClick('Custom Date')}>
                    Custom Date
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </Popover.Target>
            <Popover.Dropdown
              p={0}
              right={0}
              ref={datePickerRef}
              className="!bg-transparent border-0 outline-none !border-transparent shadow-none !w-auto mx-auto grid grid-cols-4 gap-8 mt-36 p-0 m-0">
              <DatePicker
                type="range"
                className="bg-white p-3 rounded-lg mx-auto col-start-4 "
                value={[dateRange?.startDate || null, dateRange?.endDate || null]}
                onChange={([startDate, endDate]: DatesRangeValue) => {
                  setDateRange({
                    ...dateRange,
                    startDate: startDate ? dayjs(startDate).startOf('day').toDate() : startDate,
                    endDate: endDate ? dayjs(endDate).endOf('day').toDate() : endDate,
                  });
                }}
                maxDate={new Date()}
              />
            </Popover.Dropdown>
          </Popover>
        </div>
      </div>
    );
  }
);

export default Balance;
