import { Box, Button, Center, Loader, Switch, Table, Tabs } from '@mantine/core';
import style from '../../styles/style.module.css';
import { useEffect, useState } from 'react';
import { apiAllBusinessAcc, apiSwitchAccess } from '../../../api/ApiServices';
import { notifications } from '@mantine/notifications';
import dayjs from 'dayjs';

const getRoleClass = (roleName: string) => {
  switch (roleName.toLowerCase()) {
    case 'authoriser':
      return 'bg-[#F600F966] ';
    case 'initiator':
      return 'bg-[#F9F90166]';
    case 'checker':
      return 'bg-[#00F8F766]';
    default:
      return 'bg-gray-300';
  }
};

const Settings = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [allBusinessAcc, setAllBusinessAcc] = useState<Signatories[]>([]);
  const [switchAcess, setSwitchAcess] = useState('');
  const [loadingSignatoryId, setLoadingSignatoryId] = useState<string | boolean | null | undefined>(
    null
  );

  // get all business account
  const getAllBusinessAcc = async (shouldLoad = true) => {
    if (shouldLoad) setLoading(true);
    try {
      const response = await apiAllBusinessAcc();
      setAllBusinessAcc(response.data);
      // console.log(response.data, 'data');
    } catch (error: any) {
      setError(
        notifications.show({
          color: 'red',
          message: error.message || 'An error occurred, please try again later.',
        })
      );
    } finally {
      if (shouldLoad) setLoading(false);
    }
  };

  useEffect(() => {
    getAllBusinessAcc();
  }, []);

  // switch access
  const switchAccess = async (isActive: boolean, signatoryId: string) => {
    setLoadingSignatoryId(signatoryId);
    try {
      const payload = {
        signatoryId,
      };
      const response = await apiSwitchAccess(payload);
      setLoadingSignatoryId(null);
      getAllBusinessAcc(false);
    } catch (error: any) {
      setError(
        notifications.show({
          title: error.message || 'Something went wrong',
          color: 'red',
          message: error?.data || error.message || 'An error occurred, please try again later.',
        })
      );
      setLoadingSignatoryId(null);
    }
  };

  const renderTable = () => {
    if (loading) {
      return (
        <Center>
          <Loader size={30} className="my-24" />
        </Center>
      );
    }
    if (allBusinessAcc?.length === 0) {
      return (
        <div className="text-center">
          <img
            src="/assets/dash/empty-state.png"
            alt="empty state"
            className="w-[17rem] mx-auto "
          />
          <h2 className="font-semibold mt-1">No Data</h2>
          <p className="text-sm">Currently, there are no users recorded.</p>
        </div>
      );
    }

    const currentUserRole = allBusinessAcc.find((acc) => acc.isCurrentUser)?.role.name;
    const roleName = localStorage.setItem('roleName', currentUserRole as string);

    const rows = allBusinessAcc?.map((account) => (
      <Table.Tr key={account._id}>
        <Table.Td>
          <div className="flex items-center">
            {account.isCurrentUser ? (
              <div className="bg-green-500 h-3 w-3 rounded-full mr-2 justify-end"></div>
            ) : null}
            {account.firstName} {account.lastName}
          </div>
        </Table.Td>
        <Table.Td>{account.email}</Table.Td>
        <Table.Td
          className={`px-5 inline-flex text-xs font-semibold rounded-full my-2 ${getRoleClass(
            account.role.name
          )}`}>
          {account.role.name}
        </Table.Td>
        <Table.Td>
          {account.lastLogin ? dayjs(account.lastLogin).format('ddd, MMM DD YYYY hh:mm A') : 'NA'}
        </Table.Td>
        {currentUserRole === 'authoriser' && account.role.name !== 'authoriser' && (
          <Table.Td>
            <Button
              className="!text-xs"
              disabled={!!loadingSignatoryId}
              bg={account.status === 'active' ? 'red' : 'green'}
              onClick={() => switchAccess(account.status !== 'active', account._id)}>
              {account.status === 'active' ? 'Disable' : 'Enable'}
            </Button>
          </Table.Td>
        )}
      </Table.Tr>
    ));

    return (
      <Box className="relative">
        <Table striped highlightOnHover withRowBorders={false} stickyHeader stickyHeaderOffset={0}>
          <Table.Thead>
            <Table.Tr className="bg-[#F8F8F8]">
              <Table.Th>Full Name</Table.Th>
              <Table.Th>Email Address</Table.Th>
              <Table.Th>Role</Table.Th>
              <Table.Th>Last Login</Table.Th>
              {currentUserRole === 'authoriser' && <Table.Th>Status</Table.Th>}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      </Box>
    );
  };
  return (
    <section className={`${style.main}`}>
      <h1 className="font-bold text-sm me-10 my-2 ">Available Users</h1>
      <div className="bg-[#ffffff] rounded-xl p-3 mb-5">
        <div className="max-h-[62vh] overflow-y-auto">{renderTable()}</div>
      </div>
    </section>
  );
};

export default Settings;
