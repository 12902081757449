export default function CustomCheckBox({
  checked = false,
  onClick,
  disabled = false,
  label = '',
  className = '',
  hide = false,
}: {
  checked: boolean;
  onClick: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
  disabled: boolean;
  label?: string;
  className?: string;
  hide?: boolean;
}) {
  if (hide) return <></>;

  return (
    <div className="flex justify-start items-center gap-2">
      <input
        disabled={disabled}
        onClick={onClick}
        checked={checked}
        id="check-box"
        type="checkbox"
        className={
          `w-4 h-4 text-blue-800 focus:ring-black/50 focus:ring-2 disabled:opacity-20 disabled:text-black ` +
          className
        }
      />
      {label ? <label htmlFor="check-box">{label}</label> : <></>}
    </div>
  );
}
