import { Center, Container, Group, Image, Text } from '@mantine/core';
import cbnLogo from '../../public/assets/images/CBN-logo.png';
import artboard58SourceNdicLogo from '../../public/assets/images/artboard-58-source-ndic-logo.png';

export const Footer = () => {
  return (
    <>

      <ul className='flex items-center justify-center flex-wrap gap-4 lg:gap-8 text-xs mt-20'>
        <li className='flex items-center gap-4'>
          <img src={cbnLogo} alt="" width={40} />
          <p className='lg:whitespace-nowrap'>Licensed by the Central Bank of Nigeria </p>
        </li>
        <li className='flex items-center gap-4'>
          <img src={artboard58SourceNdicLogo} alt="" width={100} />
          <p>All deposits insured by the<br />
            Nigerian Deposit Insurance Corporation.</p>
        </li>
      </ul>
      <p className='text-xs font-bold text-center my-8'>Source Microfinance Bank Limited - <span className='whitespace-nowrap'>&copy; 2024</span></p>


      {/* Old template */}
      {/* <Container mt={200} pb={20}>
        <div className="md:flex md:gap-16">
          <div className='flex gap-5'>
            <Image src="/assets/cbn.png" alt="" w={40} />
            <p className="my-auto text-sm">Licensed by the Central Bank of Nigeria </p>
          </div>
          <div className="flex gap-5">
            <Image src="/assets/ndic.png" alt="" w={100} h={40} />
            <p className="text-sm my-1">
              All deposits insured by the <br /> Nigerian Deposit Insurance Corporation.
            </p>
          </div>
        </div>

        <Text size="sm" maw={400} mx="auto" my={20}>
          <span>
            Source Microfinance Bank Limited - &copy;
            {`${new Date().getFullYear()}`}
          </span>
        </Text>
      </Container> */}
    </>
  );
};
