import React, { useContext, useEffect, useState } from 'react';
import Header from './Header';
import SideBar from './SideBar';
import style from '../../styles/style.module.css';
import InactivityModal from './InactivityModal';
import ProtectedRoutes from '../../../common/ProtectedRoutes';
import { UIContext, UIProvider, useUI } from '../../../../context/ui.context';
import { useLocation } from 'react-router';

export default function DashLayout({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  const location = useLocation();
  const [isOpenSideNav, setIsOpenSideNav] = useState(false);
  const { openMobileNav, isOpenMobileNav, isLoading } = useUI();

  useEffect(() => {
    setIsOpenSideNav(isOpenMobileNav);
  }, [isOpenMobileNav]);

  useEffect(() => {
    setIsOpenSideNav(false);
  }, [location]);



  return (
    <ProtectedRoutes>
      <div className={style.grid_container}>
        <InactivityModal />

        <header className='sticky top-0 z-50'>
          <UIProvider>
            <Header />
          </UIProvider>
        </header>

        <main className='lg:ml-10'>
          <UIProvider>
            <SideBar />
          </UIProvider>
          <div className="p-2 lg:p-4 lg:ml-72">
            <div className="p-4">
              {children}
            </div>
          </div>
        </main>
      </div>
    </ProtectedRoutes>
  );
}
