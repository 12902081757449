import { Dispatch, FormEvent, SetStateAction, useEffect, useState } from 'react';
import { useFundTransfer } from '../../../../../../context/fund-transfer.context';
import PaginatedTableRow from './PaginatedTableRow';

// const data = Array.from({ length: 30 }, (_, i) => ({
//   id: i + 1,
//   name: `Item ${i + 1}`,
//   value: Math.floor(Math.random() * 100),
// }));

const ITEMS_PER_PAGE = 5;

type PayloadProps = {
  handleDownload: (id: string) => void;
  isOneDownloading: boolean;
  setIsOneDownloading: Dispatch<SetStateAction<boolean>>;
};

enum TransactionStatus {
  Successful = 'successful',
  Pending = 'pending',
  Failed = 'failed',
  Processing = 'processing',
  Reversed = 'reversed',
}

export default function PaginatedTable({
  handleDownload,
  isOneDownloading,
  setIsOneDownloading,
}: PayloadProps) {
  const { bulkTransferList, setSelectedBulkTransactionItem, setIsBulkTransfer } = useFundTransfer();
  const [batchTransactions, setBatchTransactions] = useState<Transaction[] | any>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState(false);

  const totalPages = Math.ceil(batchTransactions.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const currentData = batchTransactions.slice(startIndex, startIndex + ITEMS_PER_PAGE);

  const handleSelectTransactionDetails = (item: Transaction) => {
    setSelectedBulkTransactionItem(item);
    setIsBulkTransfer && setIsBulkTransfer(true);
  };

  useEffect(() => {
    setIsLoading(true);
    if (bulkTransferList) {
      setIsLoading(false);
      setBatchTransactions(bulkTransferList);
    }
  }, [bulkTransferList]);

  return (
    <>
      <div className="overflow-x-auto">
        <table className="min-w-full">
          <thead className="text-[#525252] text-xs">
            <tr>
              <th className="bg-[#F5F8FF] px-4 py-3 text-left">Status</th>
              <th className="bg-[#F5F8FF] px-4 py-3 text-left rounded-l-md">Recipient Name</th>
              <th className="bg-[#F5F8FF] px-4 py-3 text-left">Amount</th>
              <th className="bg-[#F5F8FF] px-4 py-3 text-left">Bank</th>
              <th className="bg-[#F5F8FF] px-4 py-3 text-left rounded-r-md">Download</th>
            </tr>
          </thead>
          <tbody className="text-xs">
            <tr className="h-2">
              <td className="h-2" colSpan={4}></td>
            </tr>
            {currentData.map((item: Transaction) => (
              <tr
                key={item._id}
                onClick={() => handleSelectTransactionDetails(item)}
                className="bg-[#F5F8FF] cursor-pointer hover:bg-gray-100">
                <PaginatedTableRow
                  item={item}
                  handleSelectTransactionDetails={handleSelectTransactionDetails}
                  TransactionStatus={TransactionStatus}
                  currentData={currentData}
                  isOneDownloading={isOneDownloading}
                  setIsOneDownloading={setIsOneDownloading}
                />
              </tr>
            ))}

            {isLoading ? (
              <tr>
                <td colSpan={4}>
                  <p className="text-sm">Loading data...</p>
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>

        <div
          className={
            bulkTransferList && bulkTransferList.length < 8
              ? 'hidden '
              : 'flex ' + ' justify-center lg:justify-end mt-4 text-xs'
          }>
          <button
            className="px-4 py-2 mx-1 bg-gray-300 rounded disabled:opacity-50"
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}>
            Previous
          </button>
          <span className="p-2">
            Page {currentPage} of {totalPages}
          </span>
          <button
            className="p-2 mx-1 bg-gray-300 rounded disabled:opacity-50"
            onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}>
            Next
          </button>
        </div>
      </div>
    </>
  );
}
