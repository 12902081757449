import { MantineProvider, Modal } from '@mantine/core';
import { useState } from 'react';
import { useFundTransfer } from '../../../../../../context/fund-transfer.context';
import Receipt from './Receipt';

type Prop = {
  opened: boolean;
  open: () => void;
  close: () => void;
};

const Index = ({ opened, close }: Prop) => {
  /**fund transfer context */
  const { setSelectedBulkTransactionItem, setSelectedTransaction } = useFundTransfer();
  /** */

  const [modalSize, setModalSize] = useState<number>(513);

  const handleCloseModal = () => {
    setSelectedBulkTransactionItem(null);
    setSelectedTransaction(null);
    close();
  };
  return (
    <>
      <MantineProvider theme={{ defaultRadius: 'md' }}>
        <Modal
          size={modalSize}
          opened={opened}
          onClose={handleCloseModal}
          closeOnClickOutside={false}
          centered>
          <Receipt setModalSize={setModalSize} />
        </Modal>
      </MantineProvider>
    </>
  );
};

export default Index;
