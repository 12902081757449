import {
  TextInput,
  Button,
  Group,
  Card,
  PasswordInput,
  Center,
  Container,
  Anchor,
  BackgroundImage,
  Text,
  Loader,
  PinInput,
  Grid,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { yupResolver } from 'mantine-form-yup-resolver';
import * as yup from 'yup';
import { useState } from 'react';
import { notifications } from '@mantine/notifications';
import { apiLogin } from '../../api/ApiServices';
import ResetPassword from '../passwordReset/RestPasword';
import { useNavigate } from 'react-router';
import { useAuth } from '../../../context/auth.context';
import style from './Login.module.scss';
import { Label } from 'flowbite-react';
import authFeaturedImage from '../../../public/assets/images/auth-featured-image.png';

export function Login() {
  const [isLoading, setIsLoading] = useState(false);
  const [codeRequested, setCodeRequested] = useState(false);
  const { updateUser } = useAuth();

  const schema = yup.object().shape({
    email: yup.string().required('Email address is required').email('Invalid email'),
    password: yup.string().required('Password is required'),
    ...(codeRequested && { code: yup.string().required('Code is required').min(6) }),
  });
  const form = useForm({
    initialValues: {
      email: '',
      password: '',
      ...(codeRequested && { code: '' }),
    },

    validate: yupResolver(schema),
  });

  const navigate = useNavigate();
  // login email and password
  const login = async (params: any) => {
    try {
      setIsLoading(true);
      const payload = {
        ...params,
      };
      if (!codeRequested) delete payload.code;

      const resp = await apiLogin(payload);
      updateUser(resp.data);
      setTimeout(() => {
        navigate('/dashboard');
        localStorage.removeItem('passStep');
      }, 1000);
      localStorage.clear();

      const roleName = resp.data.user.role.name;

      if (roleName) {
        localStorage.setItem('roleName', roleName);
      }
      setIsLoading(false);
    } catch (error: any) {
      form.setFieldValue('code', '');
      if (error?.data?.meta?.codeRequested) {
        setCodeRequested(true);
      } else
        notifications.show({
          color: 'red',
          message: error?.message || 'An error occurred, please try again later.',
        });
    } finally {
      setIsLoading(false);
    }
  };

  const handlePinComplete = (value: string) => {
    form.setFieldValue('code', value);
    const validationResult = form.validateField('code');
    if (!validationResult.hasError) {
      login(form.values);
    }
  };

  return (
    <>
      <div className={style.container}>
        <div className={style.unevenColumns}>
          <div className={`hidden lg:block ${style.titleCol}`}>
            <Card className="bg-sky rounded-xl shadow-sm">
              <div className="px-6 py-10 flex flex-col justify-between items-center gap-8">
                <h5 className="text-3xl mb-10 font-bold tracking-tight text-white">
                  Log in to your Source Business Account and enjoy seamless transactions
                </h5>

                <img src={authFeaturedImage} alt="" draggable={false} />
              </div>
            </Card>
          </div>

          <div className={style.formCol}>
            <Card className="mx-auto shadow-sm rounded-xl h-full flex flex-col justify-end items-center text-black p-0 gap-10">
              {!codeRequested ? (
                <div className="w-full flex h-full items-center justify-center max-w-sm pt-10 px-4">
                  <form onSubmit={form.onSubmit(login)} className="w-full grid grid-cols-1 gap-4">
                    <div className="block lg:hidden col-span-1">
                      <h5 className="text-center text-xl font-bold tracking-tight">
                        Log in to your Source Business Account and enjoy seamless transactions
                      </h5>
                    </div>
                    <div className="col-span-1">
                      <TextInput
                        withAsterisk
                        radius={12}
                        styles={{
                          input: {
                            height: '50px',
                          },
                        }}
                        my={12}
                        placeholder="Email address"
                        {...form.getInputProps('email')}
                      />
                    </div>
                    <div className="col-span-1">
                      <PasswordInput
                        radius={12}
                        styles={{
                          input: {
                            height: '50px',
                          },
                        }}
                        mt={18}
                        placeholder="Password"
                        {...form.getInputProps('password')}
                      />
                    </div>
                    <div className="col-span-1">
                      <Group justify="right">
                        <Anchor
                          left={300}
                          href="/forget-password"
                          underline="never"
                          size="sm"
                          c={'#0798D0'}>
                          Forgot password?
                        </Anchor>
                      </Group>
                    </div>
                    <div className="col-span-1">
                      <Button
                        // className="bg-[url('/img/hero-pattern.svg')"
                        // className="bg-[url('/public/assets/form-bg.png')]"
                        variant="gradient"
                        gradient={{ from: '#24E3F2', to: '#0798D0', deg: 90 }}
                        fullWidth
                        radius={12}
                        h={45}
                        mb={40}
                        mt={20}
                        type="submit"
                        disabled={isLoading}>
                        {isLoading ? <Loader size={20} /> : 'Login'}
                      </Button>
                    </div>
                  </form>
                </div>
              ) : (
                <>
                  <div className="flex flex-col h-full w-full items-center justify-center max-w-sm pt-10 px-4">
                    <h5 className="block lg:hidden text-center text-xl mb-10 font-bold tracking-tight">
                      Log in to your Source Business Account and enjoy seamless transactions
                    </h5>

                    <h1 className="font-medium text-xl mb-5">Log in with OTP</h1>
                    <small>Enter OTP generated in App</small>
                    <div className="w-full">
                      <PinInput
                        radius={12}
                        type="number"
                        onWheel={(e) => e.currentTarget.blur()}
                        length={6}
                        my={12}
                        disabled={isLoading}
                        className="w-3/4 mx-auto"
                        onComplete={handlePinComplete}
                        {...form.getInputProps('code')}
                      />
                      <Group mt="md">
                        <Button
                          variant="gradient"
                          gradient={{ from: '#24E3F2', to: '#0798D0', deg: 90 }}
                          fullWidth
                          radius={12}
                          // mx={10}
                          h={45}
                          mb={40}
                          mt={20}
                          type="button"
                          // onClick={loginOtp}
                          disabled={isLoading}>
                          {isLoading ? <Loader size={20} /> : 'Login'}
                        </Button>
                      </Group>
                    </div>
                  </div>
                </>
              )}
              <p className="w-full relative right-0 left-0 text-white p-4 text-center bg-[url('/public/assets/images/bg-waves-gradient-xs.png')] bg-cover bg-no-repeat font">
                <a href="/business">
                  Don't have an account?{' '}
                  <Text span inherit fw={500}>
                    Open my account
                  </Text>
                </a>
              </p>
            </Card>
          </div>
        </div>
      </div>

      {/* Old template */}
      {/* <Container px={10}>
        <Card shadow="md" padding="xl" radius="md" maw={450} mx="auto">
          {codeRequested ? (
            <>
              <div className="text-center">
                <h1 className="font-medium text-xl mb-5">Log in with OTP</h1>
                <small>Enter OTP generated in App</small>
                <form onSubmit={form.onSubmit(login)}>
                  <PinInput
                    radius={12}
                    type="number"
                onWheel={(e) => e.currentTarget.blur()} 
                    length={6}
                    my={12}
                    className="w-3/4 mx-auto"
                    onComplete={handlePinComplete}
                    {...form.getInputProps('code')}
                  />
                  <Group mt="md">
                    <Button
                      variant="gradient"
                      gradient={{ from: '#24E3F2', to: '#0798D0', deg: 90 }}
                      fullWidth
                      radius={12}
                      // mx={10}
                      h={45}
                      mb={40}
                      mt={20}
                      type="submit"
                      // onClick={loginOtp}
                      disabled={isLoading}>
                      {isLoading ? <Loader size={20} /> : 'Login'}
                    </Button>
                  </Group>
                </form>
              </div>
            </>
          ) : (
            <form onSubmit={form.onSubmit(login)}>
              <TextInput
                withAsterisk
                radius={12}
                px={10}
                my={12}
                placeholder="Email address"
                {...form.getInputProps('email')}
              />
              <PasswordInput
                radius={12}
                px={10}
                mt={18}
                placeholder="Password"
                {...form.getInputProps('password')}
              />
              <Group justify="right">
                <Anchor
                  left={300}
                  href="/forget-password"
                  underline="never"
                  size="sm"
                  mr={20}
                  mt={10}
                  c={'#0798D0'}>
                  Forgot password?
                </Anchor>
              </Group>
              <Group mt="md">
                <Button
                  variant="gradient"
                  gradient={{ from: '#24E3F2', to: '#0798D0', deg: 90 }}
                  fullWidth
                  radius={12}
                  mx={10}
                  h={45}
                  mb={40}
                  mt={20}
                  type="submit"
                  disabled={isLoading}>
                  {isLoading ? <Loader size={20} /> : 'Login'}
                </Button>
              </Group>
            </form>
          )}
          <Card.Section mx={-60}>
            <BackgroundImage src="/assets/form-bg.png">
              <Center p="md">
                <Anchor href="/business" underline="never" size="sm" c="white">
                  Don’t have an account?
                  <Text span mx={5} inherit fw={500}>
                    Open my account
                  </Text>
                </Anchor>
              </Center>
            </BackgroundImage>
          </Card.Section>
        </Card>
      </Container> */}
    </>
  );
}
