import style from '../../styles/style.module.css';
import ApprovalsTab from './ApprovalsTab';

const Approvals = () => {
  return (
      <section className={`${style.main}`}>
        <ApprovalsTab  />
      </section>
  );
};

export default Approvals;
