import { Table, Tabs, Loader, Center } from '@mantine/core';
import { useEffect, useState, SetStateAction } from 'react';
import { apiTransaction } from '../../../api/ApiServices';
import { notifications } from '@mantine/notifications';
import dayjs from 'dayjs';
import numeral from 'numeral';
import { useLocation, useNavigate } from 'react-router';
import RecieptModal from './component/receiptModal';
import { useDisclosure } from '@mantine/hooks';
import { getCBNTransferFee } from './component/modal/Step2';
import Pagination from '../common/Pagination';
import { useAccount } from '../../../../context/account.context';
import { Search } from './component/search/Search';
import { useFundTransfer } from '../../../../context/fund-transfer.context';

const RecentTransactionTab = () => {
  /**fund transfer context */
  const { setSelectedTransaction, selectedTransaction } = useFundTransfer();
  /** */

  const [searchTerm, setSearchTerm] = useState('');
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  // paging from the backend...
  const [paging, setPaging] = useState<Paging>({ next: null, pages: 0, previous: null, total: 0 });
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [tab, setTab] = useState<string>('');
  const [opened, { open, close }] = useDisclosure(false);
  const { currentAccount } = useAccount();

  type TransactionStatus = 'successful' | 'failed' | 'processing';

  const statusStyles: Record<TransactionStatus, React.CSSProperties> = {
    successful: { color: '#16ad16' },
    processing: { color: '#f79647' },
    failed: { color: 'red' },
  };

  const fetchTransactions = async (status: string | null) => {
    setLoading(true);
    setError(null);

    const requestQuery = { ...(status && { status }), page, search: searchTerm };
    try {
      const response = await apiTransaction(requestQuery);
      setTransactions(response.data.transactions);
      if (response.data.paging) setPaging(response.data.paging);
    } catch (error: any) {
      setError('Unable to fetch transactions');
      notifications.show({
        color: 'red',
        message: error?.message || 'An error occurred, please try again later.',
      });
      
    } finally {
      setLoading(false);
    }
  };

  const location = useLocation();
  const navigate = useNavigate();
  const hash = location.hash.slice(1);

  useEffect(() => {
    if (hash) {
      setTab(hash);
    } else {
      setTab('all');
    }
  }, [location]);

  useEffect(() => {
    if (tab) {
      // reset page and paging to defaults everytime tabs change
      setPage(1);
      setPaging({ next: null, pages: 0, previous: null, total: 0 });
      fetchTransactions(tab === 'all' ? null : tab);
    }
  }, [tab]);

  useEffect(() => {
    if (tab) {
      fetchTransactions(tab === 'all' ? null : tab);
    }
  }, [page, currentAccount]);

  /**handles submit searchTerm to fetch transactions */
  const handleSubmitSearchTerm = (e: React.FormEvent) => {
    e.preventDefault();
    fetchTransactions(tab);
  };

  const handleReceipt = (transaction: Transaction) => {
    setSelectedTransaction(transaction);
    open();
    
  };

  const handleTabChange = (newTab: SetStateAction<string | null>) => {
    setTab(newTab as string);
    navigate(`#${newTab}`, { replace: true });
  };

  const renderTable = () => {
    if (loading) {
      return (
        <Center>
          <Loader size={30} className="my-24" />
        </Center>
      );
    }

    if (transactions.length === 0) {
      return (
        <div className="text-center">
          <img
            src="/assets/dash/empty-state.png"
            alt="empty state"
            className="w-[17rem] mx-auto my-8"
          />
          <h2 className="font-semibold mt-1">No Data</h2>
          <p className="text-sm">Currently, there are no {tab} payments recorded.</p>
        </div>
      );
    }

    const rows = transactions.map((transaction) => (
      <Table.Tr
        key={transaction._id}
        onClick={() => handleReceipt(transaction)}
        className="cursor-pointer">
        <Table.Td className="py-4" style={statusStyles[transaction.status as TransactionStatus]}>
          {transaction.status}
        </Table.Td>
        <Table.Td className="min-w-32">
          {transaction.createdAt
            ? dayjs(transaction.createdAt).format('ddd, MMM DD YYYY hh:mm A')
            : 'NA'}
        </Table.Td>
        <Table.Td>
          {numeral(transaction?.amount).format('0,0.00')}
          {/* +
          getCBNTransferFee(transaction?.amount, transaction.beneficiaryBankCode) || 0 */}
        </Table.Td>
        <Table.Td className="min-w-40">{transaction.narration}</Table.Td>
        <Table.Td className="min-w-32">
          {transaction.batchGroup ? `bulk-${transaction.type}` : transaction.type}
        </Table.Td>
        <Table.Td>{numeral(transaction.balance).format('0,0.00')}</Table.Td>
      </Table.Tr>
    ));

    return (
      <Table striped highlightOnHover withRowBorders={false} stickyHeader stickyHeaderOffset={0}>
        <Table.Thead>
          <Table.Tr className="bg-[#F8F8F8]">
            <Table.Th>Status</Table.Th>
            <Table.Th>Date</Table.Th>
            <Table.Th>Amount(₦)</Table.Th>
            <Table.Th>Description</Table.Th>
            <Table.Th>Type</Table.Th>
            <Table.Th>Balance</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
    );
  };

  return (
    <>
      {/* Search field for transaction table */}
      <div id="search-transaction">
        <Search setSearchTerm={setSearchTerm} onSubmitSearchTerm={handleSubmitSearchTerm} />
      </div>

      <Tabs
        value={tab}
        mt={20}
        className="bg-[#ffffff] rounded-xl p-3 mb-10"
        onChange={handleTabChange}>
        <Tabs.List className="flex-nowrap overflow-auto">
          <h1 className="font-bold text-sm me-10 my-auto">Recent Transactions</h1>
          <Tabs.Tab value="all">All</Tabs.Tab>
          <Tabs.Tab value="successful">Successful</Tabs.Tab>
          <Tabs.Tab value="processing">Processing</Tabs.Tab>
          <Tabs.Tab value="failed">Failed</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="all">
          <div className="max-h-[62vh] overflow-y-auto">{renderTable()}</div>
        </Tabs.Panel>

        <Tabs.Panel value="successful">
          <div className="max-h-[62vh] overflow-y-auto">{renderTable()}</div>
        </Tabs.Panel>

        <Tabs.Panel value="processing">
          <div className="max-h-[62vh] overflow-y-auto">{renderTable()}</div>
        </Tabs.Panel>

        <Tabs.Panel value="failed">
          <div className="max-h-[62vh] overflow-y-auto">{renderTable()}</div>
        </Tabs.Panel>
      </Tabs>

      <Pagination paging={paging} page={page} setPage={setPage} />

      <RecieptModal opened={opened} close={close} open={open} />
    </>
  );
};

export default RecentTransactionTab;
