import { Card, Center, Container, Anchor, BackgroundImage, Text, Grid, Flex } from '@mantine/core';
import { RiUserFollowLine, RiUserSettingsLine } from 'react-icons/ri';
import { HiOutlineBriefcase, HiOutlineUsers } from 'react-icons/hi2';

export function BusinessAccount() {
  return (
    <Container px={10}>
      <Card shadow="md" padding="xl" radius="md" maw={550} mx="auto">
        <Card.Section>
          <BackgroundImage src="/assets/form-bg.png" h={90}>
            <Flex
              direction={{ base: 'column', sm: 'row' }}
              gap={40}
              justify={{ sm: 'center' }}
              pt={5}>
              <Text size="lg" c="white" fw={700} mt={5} className='text-center'>
                Let's get you started with <br /> <span>Source Business Account</span>
              </Text>
            </Flex>
          </BackgroundImage>
        </Card.Section>
        <Text size="md" mt={55} fw={500}>
          Select business type
        </Text>
        <Grid mt={20} mb={60} grow className="cursor-pointer">
          <Grid.Col span={6}>
            <Card component="a" href="/sole-proprietorship">
              <Center display="block" ta="center" p={20}>
                <div className="flex justify-center">
                  <RiUserSettingsLine size={35} color="#0798D0" />
                </div>
                <Text size="md" fw={500} mt={5}>
                  Sole Proprietorship
                </Text>
              </Center>
            </Card>
          </Grid.Col>

          <Grid.Col span={6}>
            <Card component="a" href="/cooperate-account">
              <Center display="block" ta="center" p={20}>
                <div className="flex justify-center">
                  <HiOutlineUsers size={35} color="#0798D0" />
                </div>
                <Text size="md" fw={500} mt={5}>
                  Corporate Entity
                </Text>
              </Center>
            </Card>
          </Grid.Col>

          <Grid.Col span={6}>
            <Card component="a" href="/registered-association-account">
              <Center display="block" ta="center" p={20}>
                <div className="flex justify-center">
                  <RiUserFollowLine size={35} color="#0798D0" />
                </div>
                <Text size="md" fw={500} mt={5}>
                  Registered Association
                </Text>
              </Center>
            </Card>
          </Grid.Col>

          <Grid.Col span={6}>
            <Card component="a" href="/non-registered-association-account">
              <Center display="block" ta="center" p={20}>
                <div className="flex justify-center">
                  <HiOutlineBriefcase size={35} color="#0798D0" />
                </div>
                <Text size="md" fw={500} mt={5}>
                  Non-Registered Association
                </Text>
              </Center>{' '}
            </Card>
          </Grid.Col>
        </Grid>

        <Card.Section mx={-60}>
          <BackgroundImage src="/assets/form-bg.png">
            <Center p="md">
              <Anchor href="/" underline="never" size="sm" c="white">
                Already have an account?
                <Text span mx={5} inherit fw={500}>
                  Log in
                </Text>
              </Anchor>
            </Center>
          </BackgroundImage>
        </Card.Section>
      </Card>
    </Container>
  );
}
