import {
  Card,
  Container,
  BackgroundImage,
  Text,
  Flex,
  Button,
  Stepper,
} from '@mantine/core';
import { MdOutlineArrowBack } from 'react-icons/md';
import FormWizard from 'react-form-wizard-component';
import 'react-form-wizard-component/dist/style.css';
import { MouseEventHandler, SetStateAction, useEffect, useState } from 'react';
import SuccessMessage from '../common/SuccessMessage';
import EmailCode from '../common/EmailCode';
import BusinessSignatories from '../soleProprietorshipAccount/component/BusinessSignatories';
import NonRegisterIntro from './component/NonRegisterIntro';
import NonRegisterDoc from './component/NonRegisterDoc';
import ExecutivesDetails from './component/ExecutivesDetails';
import DirectorDetails from '../registeredAssociationACccount/component/DirectorDetails';
import RegisterDoc from '../registeredAssociationACccount/component/RegisterDoc';
import RegisteredIntro from '../registeredAssociationACccount/component/RegisteredIntro';

const NonRegisterAssociation = () => {
  
 const [active, setActive] = useState(0);
 const [showSuccess, setShowSuccess] = useState(false);
 const [formData, setFormData] = useState({});
 const [step, setStep] = useState(0);
 const [isLoading, setIsLoading] = useState(true);
 const steps = [
   'RegisteredIntro',
   'EmailCode',
   'RegisterDoc',
   'DirectorDetails',
   'BusinessSignatories',
   'SuccessMessage',
 ];
 const nextStep = () => setActive((current) => Math.min(current + 1, steps.length - 1));
 const prevStep = () => setActive((current) => Math.max(current - 1, 0));
 const handleNextStep = () => {
   nextStep();
   setStep((prevStep) => prevStep + 1);
 };
 const updateFormData = (newData: SetStateAction<{}>) => {
   setFormData((prevFormData) => ({ ...prevFormData, ...newData }));
 };

 const handleFinishClick = () => {
   if (active === steps.length - 2) {
     setShowSuccess(true);
     console.log(formData);
   } else {
     nextStep();
   }
 };

 useEffect(() => {
   const storedStep = parseInt(localStorage.getItem('nonRegisteredStep') || '');
   console.log('Retrieved nonRegisteredStep from localStorage:', storedStep);
   setActive(!isNaN(storedStep) ? storedStep : 0);
   setIsLoading(false);
 }, []);

 useEffect(() => {
   console.log('Setting nonRegisteredStep in localStorage:', active);
   localStorage.setItem('nonRegisteredStep', String(active));
 }, [active]);

 if (isLoading) {
   return <div>Loading...</div>;
 }

  return (
    <>
      <Container px={10}>
        <Card shadow="md" padding="xl" radius="md" maw={650} mx="auto">
          <Card.Section mx={-60}>
            <BackgroundImage src="/assets/form-bg.png" h={90}>
              <Flex
                direction={{ base: 'column', sm: 'row' }}
                gap={40}
                justify={{ sm: 'center' }}
                pt={5}>
                {/* <Image src='/assets/source-sba.png' w={50} /> */}
                <Text size="lg" c="white" mt={10}>
                  Let’s get you started with a
                  <Text inherit fw={700}>
                    Source Business Account
                  </Text>
                </Text>
              </Flex>
            </BackgroundImage>
          </Card.Section>
          {!showSuccess && (
            <>
              <Stepper active={active} size="xs" mt={35} mb={40} onStepClick={setActive}>
                <Stepper.Step disabled>
                  <NonRegisterIntro onNextStep={handleNextStep} updateFormData={updateFormData} />
                </Stepper.Step>
                <Stepper.Step disabled>
                  <EmailCode
                    onPrevStep={prevStep}
                    formData={formData}
                    onNextStep={handleNextStep}
                    step={step}
                  />
                </Stepper.Step>
                <Stepper.Step disabled>
                  <NonRegisterDoc
                    onPrevStep={prevStep}
                    onNextStep={handleNextStep}
                    updateFormData={updateFormData}
                  />
                </Stepper.Step>
                <Stepper.Step disabled>
                  <ExecutivesDetails
                    onPrevStep={prevStep}
                    onNextStep={handleNextStep}
                    updateFormData={updateFormData}
                  />
                </Stepper.Step>
                <Stepper.Step disabled>
                  <BusinessSignatories
                    onPrevStep={prevStep}
                    handleFinishClick={handleFinishClick}
                  />
                </Stepper.Step>
              </Stepper>
            </>
          )}
          {showSuccess && (
            <>
              <SuccessMessage />
            </>
          )}
        </Card>
      </Container>
    </>
  );
};

export default NonRegisterAssociation;
