import { Grid, TextInput, Text, Box, Button, Group, Loader } from '@mantine/core';
import { useForm } from '@mantine/form';
import { yupResolver } from 'mantine-form-yup-resolver';
import * as yup from 'yup';
import { FaPlus } from 'react-icons/fa';
import { MdOutlineArrowBack } from 'react-icons/md';
import { apiAddSignatories, apiRole } from '../../../api/ApiServices';
import { useEffect, useState } from 'react';
import { Checkbox } from '@mantine/core';
import React from 'react';
import AddSignatoriesTable from './AddSignatoriesTable';
import { notifications } from '@mantine/notifications';

type Props = {
  onPrevStep: () => void;
  handleFinishClick: () => void;
};
const AddSignatories = ({ onPrevStep, handleFinishClick }: Props) => {
  const [roles, setRoles] = useState<{ name: string; description: string; _id: string }[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [step, setStep] = useState(4);
  const [selectedRoleName, setSelectedRoleName] = useState('');
  const [submitting, setSubmitting] = useState(false); // Loading state for adding signatories

  const schema = yup.object().shape({
    firstName: yup.string().required('First Name is reqiured'),
    lastName: yup.string().required('First Name is reqiured'),
    email: yup.string().required('Invalid email').email('Invalid email'),
    role: yup.string().required('You need to assign a role'),
  });
  const form = useForm({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      role: '',
    },

    validate: yupResolver(schema),
  });
  // retrieve data
  React.useEffect(() => {
    console.log('onload');
    const saved = localStorage.getItem(`addSignatories${step}`);
    if (saved) {
      const jsonSaved = JSON.parse(saved);
      console.log(jsonSaved);
      form.setValues({
        ...jsonSaved,
      });
      console.log(jsonSaved);
    } else {
    }
  }, []);

  // fetch role
  const fetchData = async () => {
    try {
      const response = await apiRole();
      console.log(response);
      setRoles(response.data.roles);
    } catch (error: any) {
      setError(
        notifications.show({
          title: 'Something went wrong',
          color: 'red',
          message:
            error?.data?.message || error.message || 'An error occurred, please try again later.',
        })
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // add signatories
  const [signatory, setSignatory] = useState<any[]>([]);
  const addSignatory = async (data: any) => {
    try {
      console.log(form, 'form');
      console.log(data);
      if (form) {
        setSubmitting(true);
        setSignatory([...signatory, { ...data }]);
        console.log([...signatory, ...data], 'form');
        form.reset();
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setSubmitting(false);
    }
  };

  // remove signatories
  const removeSignatories = async (index: number) => {
    try {
      const directersCopy = [...signatory];

      directersCopy.splice(index, 1);

      setSignatory(directersCopy);
      console.log(index, 'index to remove');
    } catch (error: any) {
      setError(
        notifications.show({
          title: 'Something went wrong',
          color: 'red',
          message:
            error?.data?.message || error.message || 'An error occurred, please try again later.',
        })
      );
      console.log(error);
    }
  };

  //handle submit
  const handleSaveContinue = () => {
    const modSignatory = signatory.map((signatory) => {
      return { ...signatory };
    });
    console.log('modSignatory:', modSignatory);

    // call API
    const registerSignatories = async () => {
      try {
        setSubmitting(true);
        const id = localStorage.getItem('registeredId') || '';
        const resp = await apiAddSignatories(id, modSignatory);
        handleFinishClick();
        setSubmitting(false);
      } catch (error: any) {
        setError(
          notifications.show({
            title: 'Something went wrong',
            color: 'red',
            message:
              error?.data?.message || error.message || 'An error occurred, please try again later.',
          })
        );
      } finally {
        setSubmitting(false);
      }
    };
    registerSignatories();
  };

  return (
    <>
      <Text size="lg" mt={20} fw={500} ta="left">
        Add account signatories
      </Text>
      <Text size="sm" ta="left">
        Make sure this is the same information as on your passport or ID, so we can verify it later
        on.
      </Text>
      <form onSubmit={form.onSubmit(addSignatory)}>
        <Grid mt={20} mb={60} grow>
          <Grid.Col span={{ base: 12, lg: 6 }}>
            <TextInput
              ta="left"
              label="First Name "
              withAsterisk
              radius={5}
              px={0}
              my={{ base: 5, sm: 10 }}
              placeholder=""
              {...form.getInputProps('firstName')}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, lg: 6 }}>
            <TextInput
              ta="left"
              label="Last Name "
              withAsterisk
              radius={5}
              px={0}
              my={{ base: 5, sm: 10 }}
              placeholder=""
              {...form.getInputProps('lastName')}
            />
          </Grid.Col>

          <Grid.Col span={12}>
            <TextInput
              ta="left"
              label="Email Address"
              withAsterisk
              radius={5}
              px={0}
              my={{ base: 5, sm: 10 }}
              placeholder=" "
              {...form.getInputProps('email')}
            />
          </Grid.Col>
          {loading ? (
            <Loader size={22} />
          ) : (
            <Grid bg="#F5F8FF" p={12} mt={30} mb={20} style={{ borderRadius: '8px' }}>
              {roles.map((role, index) => (
                <Grid.Col span={4} mt={10} key={index}>
                  <Checkbox
                    label={role.name}
                    fw={500}
                    className="capitalize"
                    value={role._id}
                    description={role.description}
                    {...form.getInputProps('role')}
                    checked={form.values.role === role._id}
                    onChange={() => {
                      form.setFieldValue('role', role._id); // Set the role ID in form state
                      setSelectedRoleName(role.name); // Store the role name in component state
                    }}
                  />
                </Grid.Col>
              ))}
            </Grid>
          )}
          <Box>
            <Button
              variant="outline"
              fw={500}
              size="sm"
              radius="xl"
              color="#0798D0"
              my={{ base: 5, sm: 10 }}
              leftSection={<FaPlus size={14} />}
              type="submit"
              disabled={submitting}>
              Add Signatories
            </Button>
          </Box>
        </Grid>

        <div className="absolute bottom-8 end-20">
          <Group justify="center" mt="xl">
            <Button
              bg="transparent"
              color="#0798D0"
              w={180}
              radius={12}
              mx={10}
              h={37}
              leftSection={<MdOutlineArrowBack size={16} />}
              variant="light"
              type="submit"
              component="a"
              onClick={onPrevStep}>
              Back
            </Button>
            <Button
              variant="gradient"
              gradient={{ from: '#24E3F2', to: '#0798D0', deg: 90 }}
              w={180}
              radius={12}
              mx={10}
              type="button"
              onClick={handleSaveContinue}
              disabled={submitting}>
              {submitting ? <Loader size={20} /> : 'Save and Continue'}
            </Button>
          </Group>
        </div>
      </form>

      <AddSignatoriesTable
        signatory={signatory}
        roles={roles}
        removeDirector={removeSignatories}
      // selectedRoleName={selectedRoleName}
      />
    </>
  );
};
export default AddSignatories;
