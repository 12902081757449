import { Dispatch, SetStateAction } from 'react';
import PaginatedTable from './PaginatedTable';

type PayloadProps = {
  handleDownload: (id: string) => void;
  isOneDownloading: boolean;
  setIsOneDownloading: Dispatch<SetStateAction<boolean>>;
};
export default function BulkTransferTable({
  handleDownload,
  isOneDownloading,
  setIsOneDownloading,
}: PayloadProps) {
  return (
    <>
      <PaginatedTable
        handleDownload={handleDownload}
        isOneDownloading={isOneDownloading}
        setIsOneDownloading={setIsOneDownloading}
      />
    </>
  );
}
