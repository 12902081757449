import style from '../../styles/style.module.css';
import { GoHistory } from 'react-icons/go';
import { IoMdNotificationsOutline } from 'react-icons/io';
import { IoSettingsOutline } from 'react-icons/io5';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { SlLogout } from 'react-icons/sl';
import { PiCopy } from 'react-icons/pi';
import { MdDashboard } from 'react-icons/md';
import { RxQuestionMarkCircled } from 'react-icons/rx';
import { RiExchangeFundsLine } from 'react-icons/ri';
import { apiLogout } from '../../../api/ApiServices';
import { notifications } from '@mantine/notifications';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useApproval } from '../../../../context/approval.context';
import { useAuth } from '../../../../context/auth.context';
import { useAccount } from '../../../../context/account.context';
import { useUI } from '../../../../context/ui.context';

const SideBar = () => {
  //   const contextValue = useContext(UIContext);
  const { openMobileNav, isOpenMobileNav, isLoading } = useUI();

  const location = useLocation();

  const handleOpenSidenav = () => {
    openMobileNav(true);
  };

  const handleCloseSidenav = () => {
    openMobileNav(false);
  };

  const [error, setError] = useState('');
  const { count, getApprovalCount } = useApproval();
  const { updateUser } = useAuth();
  const { currentAccount } = useAccount();
  const navigate = useNavigate();

  const sidenavRef = useRef<any>(null);

  const logout = async () => {
    try {
      const response = await apiLogout();
      updateUser(null);
      navigate('/login');
      // console.log(response);
    } catch (error) {
      notifications.show({
        color: 'red',
        message: 'An error occurred, please try again later.',
      });
      setError('Unable to logout');
    }
  };

  const handleClickOutside = useCallback((event: any) => {
    if (sidenavRef.current && !sidenavRef.current.contains(event.target)) {
      handleCloseSidenav();
    }
  }, []);

  useEffect(() => {
    openMobileNav(false);
  }, [location]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    getApprovalCount();
  }, [currentAccount]);

  const sidebarItems = [
    { path: '/dashboard', label: 'Dashboard', icon: <MdDashboard className="my-auto" /> },
    {
      path: '/dashboard/funds-transfer',
      label: 'Funds Transfer',
      icon: <RiExchangeFundsLine className="my-auto" />,
    },
    {
      path: '/dashboard/transaction-history',
      label: 'Transaction History',
      icon: <GoHistory className="my-auto" />,
    },
    {
      path: '/dashboard/approvals',
      label: 'Approvals',
      icon: <IoMdNotificationsOutline className="my-auto" />,
      count:
        count > 0 ? (
          <div className="rounded-full bg-red-500 min-w-5 min-h-5 flex justify-center items-center text-white text-[0.55rem] animate-ping-short font-semibold">
            {count}
          </div>
        ) : null,
    },
    {
      path: '/dashboard/bank-statement',
      label: 'Bank Statement',
      icon: <PiCopy className="my-auto" />,
    },
    {
      path: '/dashboard/support',
      label: 'Contact Support',
      icon: <RxQuestionMarkCircled className="my-auto" />,
    },
    {
      path: '/dashboard/settings',
      label: 'Settings',
      icon: <IoSettingsOutline className="my-auto" />,
    },
  ];

  return (
    <>
      {/* className={(isShowPortalSideNav ? 'translate-x-0 ' : '-translate-x-full ') + `bg-theme-clr-portal-secondary pt-28 fixed top-0 left-0 z-40 w-64 h-screen 
        transition-transform -translate-x-full sm:translate-x-0`} aria-label="Sidebar"> */}

      {/* <div className="bg-white lg:bg-transparentv pt-28 fixed top-0 left-0 lg:left-10 z-40 w-64 h-screen overflow-auto"> */}

      {/* onClick={handleToggleMobileMenu} */}
      <button
        onClick={handleOpenSidenav}
        data-drawer-target="default-sidebar"
        data-drawer-toggle="default-sidebar"
        aria-controls="default-sidebar"
        type="button"
        className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
        <span className="sr-only">Open sidebar</span>
        <svg
          className="w-6 h-6"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg">
          <path
            clipRule="evenodd"
            fillRule="evenodd"
            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
        </svg>
      </button>
      {/*  */}
      <div
        ref={sidenavRef}
        className={
          (isOpenMobileNav ? 'translate-x-0 ' : '-translate-x-full ') +
          `bg-white lg:bg-transparent pt-28 fixed top-0 bottom-0 left-0 z-40 w-72 overflow-auto primary-scrollbar  
            transition-transform -translate-x-full lg:translate-x-10`
        }>
        <section className={`${style.sidebar} shadow-sm rounded-lg bg-white`}>
          <button
            onClick={() => handleCloseSidenav()}
            className="lg:hidden px-8 inline-flex items-center text-sm text-gray-500 rounded-lg focus:outline-none focus:ring-2">
            <span className="sr-only">Close sidebar</span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M18 6L6 18"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6 6L18 18"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>

          <div className="grid grid-rows-8 grid-flow-col gap-4 px-8 py-8 mt-4">
            {sidebarItems.map((item, index) => (
              <div
                key={index}
                className={`flex gap-2 ${
                  location.pathname === item.path
                    ? 'bg-theme-clr-primary p-2 rounded-lg text-white'
                    : ''
                }`}>
                {item.icon}
                <Link
                  to={item.path}
                  className="my-auto"
                  onClick={async () => {
                    await getApprovalCount();
                  }}>
                  <div className="flex justify-between items-center gap-3">
                    <span>{item.label}</span>
                    {item.count}
                  </div>
                </Link>
              </div>
            ))}

            <div className="flex gap-2 row-start-10">
              <SlLogout className="my-auto" />
              <p className="cursor-pointer" onClick={logout}>
                Log out
              </p>
            </div>

            {/* <div className="flex gap-2">
            <SlQuestion className="my-auto" />
            <Link to="/dashboard/support">Support</Link>
          </div> */}
          </div>
        </section>
      </div>
    </>
  );
};

export default SideBar;
