import { createContext, useContext, useState } from 'react';

// Define the context value type
export interface FundTransferContextType {
  isLoading: boolean;
  selectedTransaction: Transaction | null;
  bulkTransferList: Transaction[] | [];
  selectedBulkTransactionItem: Transaction | null;
  isShowBulkItemDetails: boolean;
  isBulkTransfer: boolean;
  setBulkTransferList: (transactions: Transaction[]) => void;
  setSelectedTransaction: (transaction: Transaction | null) => void;
  setSelectedBulkTransactionItem: (transaction: Transaction | null) => void;
  setIsShowBulkItemDetails: (isOpen: boolean) => void;
  setIsOpenDetailsView?: (isOpen: boolean) => void;
  setIsBulkTransfer?: (isOpen: boolean) => void;
}

const FundTransferContext = createContext<FundTransferContextType>({
  isLoading: false,
  selectedTransaction: null,
  bulkTransferList: [],
  selectedBulkTransactionItem: null,
  isShowBulkItemDetails: false,
  isBulkTransfer: false,
  setSelectedTransaction: () => {},
  setBulkTransferList: () => {},
  setIsShowBulkItemDetails: () => {},
  setSelectedBulkTransactionItem: () => {},
  setIsBulkTransfer: () => {},
});

const FundTransferProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isShowBulkItemDetails, setIsShowBulkItemDetails] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState<Transaction | null>(null);
  const [selectedBulkTransactionItem, setSelectedBulkTransactionItem] =
    useState<Transaction | null>(null);
  const [bulkTransferList, setBulkTransferList] = useState<Transaction[]>([]);
  const [isBulkTransfer, setIsBulkTransfer] = useState<boolean>(false);

  return (
    <FundTransferContext.Provider
      value={{
        isLoading,
        selectedTransaction,
        selectedBulkTransactionItem,
        setSelectedTransaction,
        bulkTransferList,
        setBulkTransferList,
        isShowBulkItemDetails,
        setIsShowBulkItemDetails,
        setSelectedBulkTransactionItem,
        isBulkTransfer,
        setIsBulkTransfer,
      }}>
      {children}
    </FundTransferContext.Provider>
  );
};

const useFundTransfer = () => useContext(FundTransferContext);

export { FundTransferProvider, useFundTransfer };
