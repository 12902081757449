'use client';

export function Search({ setSearchTerm, onSubmitSearchTerm }: any) {
  const handleSearch = (val: string) => {
    setSearchTerm(val);
  };
  return (
    <form onSubmit={onSubmitSearchTerm}>
      <div className="max-w-full my-8">
        <div className="mb-2 flex items-stretch gap-2">
          <input
            id="searchText"
            type="text"
            onChange={(e) => handleSearch(e.target.value)}
            className="w-full !rounded focus:ring-theme-clr-primary-200 focus:border-theme-clr-primary-200 text-sm"
            placeholder="Search beneficiary account number"
          />
          <button className="bg-theme-clr-primary hover:bg-theme-clr-primary-200 text-white text-sm font-medium flex items-center justify-center px-4 lg:px-6 rounded focus:ring-2 focus:outline-none focus:ring-theme-clr-primary-200/50 focus:bg-theme-clr-primary focus:border-theme-clr-primary-200">
            Search
          </button>
        </div>
      </div>
    </form>
  );
}
