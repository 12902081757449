import { useState } from 'react';
import useIdleTimeout, { prompt_timeout } from './hooks/useIdleTimeout';
import { Button, Modal } from '@mantine/core';
import { useNavigate } from 'react-router';
import { notifications } from '@mantine/notifications';
import { apiLogout } from '../../../api/ApiServices';
import { useAuth } from '../../../../context/auth.context';

function InactivityModal() {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const { updateUser } = useAuth();

  const handleBeforeIdle = () => {
    if (!showModal) {
      notifications.show({
        title: 'Session Timeout Warning',
        color: 'orange',
        message: `Due to inactivity, you will be logged out in ${prompt_timeout / 1000} seconds.`,
        autoClose: 5000,
      });
    }
  };

  const logout = async () => {
    try {
      await apiLogout();
      setShowModal(true);
    } catch (error) {
      notifications.show({
        title: 'Logout',
        color: 'red',
        message: 'An error occurred, please try again later.',
      });
    }
  };

  const { isIdle } = useIdleTimeout({
    onBeforeIdle: handleBeforeIdle,
    idleTime: 5, // 5mins
    atIdle: logout,
  });

  const closeModal = async () => {
    if (!isIdle) {
      await logout();
    }
    updateUser(null);
    navigate('/login');
    setShowModal(false);
  };

  return (
    <Modal
      size={513}
      opened={showModal}
      onClose={closeModal}
      title={
        <p className="font-semibold tracking-wide text-red-400 text-xl">You have been logged out</p>
      }
      overlayProps={{
        opacity: 1,
        blur: 4,
        color: '#000',
      }}
      closeOnClickOutside={false}
      centered>
      <main>
        <h2 className="text-base">
          You have been logged out due to inactivity. Please log in again to continue using the
          application.
        </h2>
      </main>
      <footer className="flex justify-end items-center mt-5 mb-3">
        <Button variant="filled" radius={4} type="button" onClick={closeModal}>
          Ok
        </Button>
      </footer>
    </Modal>
  );
}

export default InactivityModal;
