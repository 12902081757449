import { Loader } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import React, { Dispatch, FormEvent, SetStateAction, useState } from 'react';
import { useFundTransfer } from '../../../../../../context/fund-transfer.context';
import { apiTranReceipt } from '../../../../../api/ApiServices';

type PayloadProps = {
  item: Transaction;
  handleSelectTransactionDetails: (item: Transaction) => void;
  TransactionStatus: any;
  currentData: any[];
  isOneDownloading: boolean;
  setIsOneDownloading: Dispatch<SetStateAction<boolean>>;
};

export default function PaginatedTableRow({
  item,
  TransactionStatus,
  currentData,
  isOneDownloading,
  setIsOneDownloading,
}: PayloadProps) {
  /**fund transfer context */
  const { selectedTransaction } = useFundTransfer();

  /** */
  const [isDownloading, setIsDownloading] = useState(false);

  const downloadTransaction = (e: FormEvent, id: string) => {
    e.stopPropagation();
    handleDownload(id);
  };

  const handleDownload = async (id: string) => {
    if (!isOneDownloading) {
      setIsOneDownloading(true);
    } else {
      return false;
    }
    setIsDownloading(true);
    try {
      // await new Promise((res) => setTimeout(res, 5000));
      const data = await apiTranReceipt(id);
      const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
      const hideLink = document.createElement('a');
      hideLink.style.display = 'none';
      hideLink.href = url;
      hideLink.download = `${selectedTransaction?.beneficiaryAccountName || ''} Receipt.pdf`;
      document.body.appendChild(hideLink);
      hideLink.click();
      document.body.removeChild(hideLink);
      setIsDownloading(false);
      setIsOneDownloading(false);
    } catch (error: any) {
      console.error('Error in handleDownload:', error);
      setIsDownloading(false);
      setIsOneDownloading(false);

      notifications.show({
        title: 'Download Failed',
        color: 'red',
        message: error.message || 'An error occurred, please try again later.',
      });
    }
  };

  return (
    <>
      <td
        className={
          (item.status === TransactionStatus.Failed ? 'text-red-400 ' : 'text-green-400 ') +
          'font-semibold px-4 py-3'
        }>
        {item.status}
      </td>
      <td
        style={
          currentData &&
          (currentData[0] === item
            ? { borderTopLeftRadius: '7px' }
            : currentData[currentData.length - 1] === item
            ? { borderBottomLeftRadius: '7px' }
            : {})
        }
        className="px-4 py-3">
        {item.beneficiaryAccountName}
      </td>
      <td className="px-4 py-3">{item.amount}</td>
      <td className="px-4 py-3">{item.beneficiaryBankName}</td>
      <td
        style={
          currentData[0] === item
            ? { borderTopRightRadius: '7px' }
            : currentData[currentData.length - 1] === item
            ? { borderBottomRightRadius: '7px' }
            : {}
        }
        className="px-4 py-3 text-center">
        <button
          onClick={(e) => {
            downloadTransaction(e, item._id);
          }}
          className="flex items-center justify-center w-full disabled:cursor-not-allowed"
          disabled={isDownloading || isOneDownloading}>
          {isDownloading ? (
            <>
              <span className="sr-only">Downloading</span>
              <Loader size={16} />
            </>
          ) : (
            <>
              <span className="sr-only">Download</span>
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M2.25 14.25H15.75V15.75H2.25V14.25ZM9.75 9.8787L14.3033 5.32538L15.364 6.38604L9 12.75L2.63604 6.38604L3.6967 5.32538L8.25 9.8787V1.5H9.75V9.8787Z"
                  fill="#0798D0"
                />
              </svg>
            </>
          )}
        </button>
      </td>
    </>
  );
}
